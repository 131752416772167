import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Page from "../UI/Page";
import { useState, useEffect } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { getActivityLogs } from "../../services/common.service";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import StatsCard from "../Reports/StatsCard";
import { useNavigate } from "react-router-dom";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads";

const ReportAll = ({
  reportData,
  handleChange,
  interval,
  setInterval,
  reportFigures,
  popup,
  setPopup,
  showChip,
  setShowChip,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleIntervals,
  setIsSetClicked,
  page,
  setPage,
  count,
  rowsPerPage,
  handlePageChange,
  afterRemoveChip,
  customDate,
  setCustomDate,
  leadFilter,
  handleLeadFilter,
  status,
  countries,
  salesOwners,
  handleSalesOwnerForRender,
  allChecked,
  setAllChecked,
  currentUser,
  handleOnlyOneSalesOwner,
}) => {
  // const [isValidDate, setisValidDate] = useState(false);
  console.log(currentUser);
  const navigate = useNavigate();
  const intervals = [
    {
      label: "Last Quarter",
      value: "Last Quarter",
    },
    {
      label: "Current Quarter",
      value: "Current Quarter",
    },
    {
      label: "Current Month",
      value: "Current Month",
    },
    {
      label: "Last 2 Months",
      value: new Date(
        new Date().setDate(new Date().getDate() - 60)
      ).toDateString(),
    },
    {
      label: "Last Month",
      value: new Date(
        new Date().setDate(new Date().getDate() - 30)
      ).toDateString(),
    },

    { label: "Custom", value: "Custom" },
  ];

  const isValidDate = (date) => {
    console.log(date, "reset date ");
    let dates = new Date(date);

    // Check if date is a valid Date object and its year is greater than 1000
    return dates instanceof Date && !isNaN(dates) && dates.getFullYear() > 1000;
  };

  const handleStartDateChange = (e) => {
    console.log("e", e);

    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }

    if (endDate.getTime() > e) {
      setStartDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleEndDateChange = (e) => {
    console.log("e", e);
    const selectedDate = new Date(e);
    if (!isValidDate(selectedDate)) {
      toast.warn("Invalid date format");
      return;
    }
    if (startDate.getTime() < e) {
      setEndDate(e);
    } else {
      toast("End date can not be less than or equal to start date");
    }
  };

  const handleClose = () => {
    setPopup(false);
  };

  const handleSet = () => {
    if (!isValidDate(startDate || endDate)) {
      toast.warn("Invalid  date format");
      return;
    }
    setShowChip(true);
    handleClose();
    setCustomDate(true);
  };

  const formatCustomDate = (value) => {
    const splitValue = value.split(" ");
    splitValue.shift();
    const finalValue = splitValue.join(" ");
    return finalValue;
  };
  const routingReportToLeadDetailsPage = (id, contactId) => {
    navigate(`/Lead/${id}/${contactId}`);
  };
  const leadDynamicTitel = useDynamicLeadTitle();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card elevation={3} variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={10.5}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    {showChip ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                        }}
                      >
                        <Chip
                          label={`${formatCustomDate(
                            new Date(startDate).toDateString()
                          )} - ${formatCustomDate(
                            new Date(endDate).toDateString()
                          )}`}
                          variant="outlined"
                          onDelete={afterRemoveChip}
                        />
                      </div>
                    ) : (
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-dateRange-select-label">
                          Interval
                        </InputLabel>
                        <Select
                          sx={{ maxHeight: "40px" }}
                          labelId="demo-dateRange-select-label"
                          id="demo-dateRange-select"
                          label="Interval"
                          name="interval"
                          value={interval}
                          onChange={handleChange}
                        >
                          {intervals.map((interval, index) => {
                            return (
                              <MenuItem key={index} value={interval.value}>
                                {interval.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        sx={{ maxHeight: "40px" }}
                        multiple
                        label="Status"
                        name="status"
                        value={leadFilter.status}
                        renderValue={(value) =>
                          value.length === status.length
                            ? ["ALL"]
                            : value.join(", ")
                        }
                        onChange={handleLeadFilter}
                      >
                        <MenuItem value="ALL">
                          <Checkbox checked={allChecked.status} />
                          <ListItemText primary="ALL" />
                        </MenuItem>
                        {status.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              <Checkbox
                                checked={
                                  allChecked.status
                                    ? true
                                    : leadFilter.status.indexOf(item) > -1
                                }
                              />
                              <ListItemText primary={item} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Countries</InputLabel>
                      <Select
                        sx={{ maxHeight: "40px" }}
                        multiple
                        name="country"
                        label="Countries"
                        value={leadFilter.country}
                        renderValue={(value) =>
                          value.length === countries.length
                            ? ["ALL"]
                            : value.join(", ")
                        }
                        onChange={handleLeadFilter}
                      >
                        <MenuItem value="ALL">
                          <Checkbox checked={allChecked.country} />
                          <ListItemText primary="ALL" />
                        </MenuItem>
                        {countries.map((country, index) => {
                          return (
                            <MenuItem key={index} value={country}>
                              <Checkbox
                                checked={
                                  allChecked.country
                                    ? true
                                    : leadFilter.country.indexOf(country) > -1
                                }
                              />
                              <ListItemText primary={country} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Sales Owner
                      </InputLabel>
                      {currentUser && currentUser.role === "ADMIN" ? (
                        <Select
                          id="demo-simple-select-label"
                          notched={true}
                          sx={{ maxHeight: "40px" }}
                          multiple
                          label="Sales Owner"
                          name="salesOwner"
                          value={leadFilter.salesOwner}
                          renderValue={(value) => {
                            return handleSalesOwnerForRender(value);
                          }}
                          onChange={handleLeadFilter}
                        >
                          <MenuItem value="ALL">
                            <Checkbox checked={allChecked.salesOwner} />
                            <ListItemText primary="ALL" />
                          </MenuItem>
                          {salesOwners.map((salesOwner, index) => {
                            if (salesOwner.active_status === true) {
                              return (
                                <MenuItem key={index} value={salesOwner._id}>
                                  <Checkbox
                                    checked={
                                      allChecked.salesOwner
                                        ? true
                                        : leadFilter.salesOwner.includes(
                                            salesOwner._id
                                          )
                                    }
                                  />
                                  <ListItemText primary={salesOwner.name} />
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      ) : (
                        <Select
                          label="Sales Owner"
                          name="salesOwner"
                          value={currentUser && currentUser.name}
                          renderValue={(value) => {
                            return [currentUser && currentUser.name];
                          }}
                          onChange={handleOnlyOneSalesOwner}
                        >
                          <MenuItem value={currentUser._id}>You</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                <div>
                  <Tooltip title="Fetch Results">
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        handleIntervals(customDate ? "Custom" : interval);
                      }}
                      startIcon={<ManageSearchIcon />}
                    >
                      FETCH
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3} variant="outlined">
          <CardContent>
            <Grid container spacing={1} sx={{ paddingBottom: "5px" }}>
              <Grid item xs={2}>
                <StatsCard value={reportData.length} label={"Total"} />
              </Grid>

              <Grid item xs={2}>
                <StatsCard value={reportFigures.lead} label={"Lead"} />
              </Grid>

              <Grid item xs={2}>
                <StatsCard value={reportFigures.prospect} label={"Prospect"} />
              </Grid>

              <Grid item xs={2}>
                <StatsCard
                  value={reportFigures.opportunity}
                  label={"Opportunity"}
                />
              </Grid>

              <Grid item xs={2}>
                <StatsCard
                  label={"Drop"}
                  value={reportFigures.drop}
                  color={reportFigures.drop > 0 ? "#F00" : "black"}
                />
              </Grid>

              <Grid item xs={2}>
                <StatsCard
                  label={"Close"}
                  value={reportFigures.close}
                  color={reportFigures.close > 0 ? "#0F0" : "black"}
                />
              </Grid>
            </Grid>
            {reportData.length ? (
              <>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">{` ${leadDynamicTitel?.displayName.singularName}`}</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Country</TableCell>
                        <TableCell align="left">Last Updated At</TableCell>
                        <TableCell align="left">Sales Owner</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((value) => {
                          return value;
                        })
                        .map((value, index) => {
                          console.log("reports value", value);
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                              hover
                              onClick={(e) => {
                                e.stopPropagation();
                                routingReportToLeadDetailsPage(
                                  value._id,
                                  value.contact_id
                                );
                              }}
                            >
                              <TableCell align="left">
                                {value.summary}
                              </TableCell>
                              <TableCell align="left">{value.status}</TableCell>
                              <TableCell align="left">
                                {value.country}
                              </TableCell>
                              <TableCell align="left">
                                {new Date(value.updatedAt).toDateString()}
                              </TableCell>
                              <TableCell align="left">
                                {value.salesOwner}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography>{`No ${leadDynamicTitel?.displayName.singularName}
                      Records`}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog open={popup} onClose={() => {}}>
        <DialogTitle textAlign="center">Define Interval</DialogTitle>
        <DialogContent>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  fullWidth
                  label="From"
                  inputFormat="MMM/dd/yyyy"
                  value={startDate}
                  maxDate={new Date().setDate(new Date().getDate() - 1)}
                  name="startDate"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} sx={{ marginTop: "11px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  required
                  fullWidth
                  label="To"
                  inputFormat="MMM/dd/yyyy"
                  // minDate={project.timeline.deadline}
                  // minDate={}
                  value={endDate}
                  name="endDate"
                  maxDate={new Date()}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setInterval("");
              setStartDate(new Date().setDate(new Date().getDate() - 1));
            }}
            sx={{ color: "red" }}
          >
            CANCEL
          </Button>
          <Button onClick={handleSet}>SET</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ReportAll;
