import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormLabel,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  addUser,
  getUsers,
  updateUserStatus,
} from "../../services/users.service";
import { ToastContainer, toast } from "react-toastify";
import Page from "../../components/UI/Page";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Scrollbar from "../../components/UI/Scrollbar";
import Iconify from "../../components/UI/Iconify";
import SearchNotFound from "../../components/SearchNotFound";
import { UserListHead } from "../../sections/@dashboard/user";
import SearchUser from "../../components/SearchDropDown/SearchDropDownUser";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getRolesName } from "../../services/roles.service";

//import compose mail
import EmailDialog from "../../components/Dialogs/EmailDialog";

import ContextMenu from "../../components/UI/ContextMenu";
import { AuthContext } from "../../context/auth.context";
import { getPassword } from "../../utils/indexedDb";
import {
  addTenant,
  changeStatusBySuperAdmin,
  getTenants,
} from "../../services/superAdmin.service";

const ITEM_HEIGHT = 48;

let hitApi = true;
var regex = /^[a-zA-Z ]*$/;
var regexPhone = /^\+?\d*$/;
let rolesArray = [];

export default function Users() {
  const {
    currentUser = {},
    toggleShowMailConsentPopup,
    emailPassword,
    setEmailPassword,
  } = React.useContext(AuthContext);
  const { isTenant, role: currentUserRole } = currentUser;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });

  const [enablePopupOpen, setEnablePopupOpen] = React.useState({
    state: false,
    onSuccess: () => {},
  });
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [role, setRole] = React.useState(
    currentUser.role === "SUPER_ADMIN"
      ? isTenant
        ? "ADMIN"
        : "SUPER_ADMIN"
      : null
  );
  const [password, setPassword] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");

  const [users, setUsers] = useState([]);

  const [count, setCount] = useState(1);

  //This variable is used for UI pagination
  const [page, setPage] = useState(0);

  const [pageCount, setPageCount] = useState(1);

  const [order, setOrder] = useState("");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [column, setColumn] = useState("");
  const [typeText, setTypeText] = useState("");
  const handleClickOpen = () => {
    setOpenAddUser(true);
  };

  const [saveButton, setSaveButton] = useState(false);
  const [loaderDialog, setLoaderDialog] = React.useState(false);
  const [roles, setRoles] = useState([]);

  //mail dialog
  const [mailTo, setMailTo] = useState("");
  const [contextMailClick, setContextMailClick] = useState(false);
  const [contextMailName, setContextMailName] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleDeletePopupOpen = (_id, status) => {
    setDeletePopupOpen({
      state: true,
      onSuccess: async () => {
        console.log("_id", _id);
        try {
          let response;
          if (currentUser?.role === "SUPER_ADMIN") {
            response = await changeStatusBySuperAdmin(
              _id,
              {
                active_status: status,
              },
              isTenant
            );
          } else {
            response = await updateUserStatus(_id, status);
          }
          if (response.data.status === true) {
            toast("User disabled successfully");
            setUsers([]);
            setPageCount(1);
            if (currentUser?.role === "SUPER_ADMIN") {
              fetchUsersForSuperAdmin();
            } else {
              fetchUsers();
            }
            console.log(users);
          }
          // else {
          //   navigate(`/unauthorized`);
          // }
          handleClose();
        } catch (error) {
          console.log(error);
        }
      },
    });
    return _id;
  };

  const handleEnablePopupOpen = (_id, status) => {
    setEnablePopupOpen({
      state: true,
      onSuccess: async () => {
        try {
          let response;
          if (currentUser?.role === "SUPER_ADMIN") {
            response = await changeStatusBySuperAdmin(
              _id,
              {
                active_status: status,
              },
              isTenant
            );
          } else {
            response = await updateUserStatus(_id, status);
          }

          if (response.data.status === true) {
            toast("User enabled successfully");
            setUsers([]);
            setPageCount(1);
            if (currentUser?.role === "SUPER_ADMIN") {
              fetchUsersForSuperAdmin();
            } else {
              fetchUsers();
            }
            console.log(users);
          }
          // else {
          //   navigate(`/unauthorized`);
          // }
          handleClose();
        } catch (error) {
          console.log(error);
        }
      },
    });
    return _id;
  };

  const handleClose = () => {
    resetState();
    setOpen(false);
    setOpenAddUser(false);
    setDeletePopupOpen(false);
    setEnablePopupOpen(false);
    setAgreeSetPasswordDialog(false);
  };

  const handleClickMenu = (event) => {
    console.log("====3 dots clicked====");
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "role", label: "Role", alignRight: false },
    { id: "phone", label: "Phone", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "actions", label: "Actions", alignRight: true },
  ];

  const SUPER_ADMIN_TABLE_HEAD = [
    { id: "name", label: "Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "phone", label: "Phone", alignRight: false },
    { id: "companyName", label: "Company Name", alignRight: false },
    { id: "dbName", label: "DB Name", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "actions", label: "Actions", alignRight: true },
  ];

  // ----------------------------------------------------------------------

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  // const [contacts, setContacts] = useState([]);
  // const [searchedContacts, setSearchedContacts] = useState([...contacts]);
  const [searchTerm, setsearchTerm] = useState("");

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (order === "") setOrder("asc");
    if (order === "asc") setOrder("desc");
    setOrderBy(property);
    if (order === "desc") {
      setOrder("");
      setOrderBy("");
    }
  };

  const handleSetRole = (e) => {
    // console.log(e.target.name, e.target.value);
    // if (e.target.value === "ADMIN") {
    //   setRole("ADMIN");
    // }
    // if (e.target.value === "SALES") {
    //   setRole("SALES");
    // }
    // if (e.target.value === "DELIVERY_LEAD") {
    //   setRole("DELIVERY_LEAD");
    // }
    // if (e.target.value === "MEMBER") {
    //   setRole("MEMBER");
    // }
    setRole(e.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (userId, userData) => {
    console.log("Inside handle click", userId, userData);
    navigate(`/users/${userId}`, { state: { ...userData } });
  };

  const handleChangePage = (event, newPage) => {
    console.log("Inside handle change page", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > users.length &&
      users.length < count
    ) {
      console.log("Fetching new data");
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(event.target.value);
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const handleDelete = async (_id, status) => {
    console.log("handle delete called for" + _id);
    handleDeletePopupOpen(_id, status);
  };

  const handleEnable = async (_id, status) => {
    console.log("handle enable called for" + _id);
    handleEnablePopupOpen(_id, status);
  };

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };
  const isValidDateFormat = (date) => {
    const dateString = new Date(date).getFullYear();
    const currentYear = new Date().getFullYear();
    const isValidYear = dateString > 1900 && dateString <= currentYear;

    return isValidYear ? date instanceof Date && !isNaN(date.getTime()) : false;
  };

  const validatePhone = (data) => {
    console.log(data.length);
    let upperFlag = "Up";
    let lowerFlag = "Low";
    if (data.length > 0) {
      if (data.length >= 10 && data.length <= 14) {
        const regexPattern = /^\+?\d+$/;
        // return result = regexPattern.test(data);
        if (!regexPattern.test(data)) {
          return upperFlag;
        }
      } else {
        return lowerFlag;
      }
      // return data.regex(/^\+?\d+$/) ? true : false
    }
    return null;
  };

  const validateAge = (data) => {
    console.log("getTime", new Date(data).getTime());
    var diff = Date.now() - new Date(data).getTime();
    console.log("diff", diff);
    var diffInYear = new Date(diff);
    console.log(diffInYear);
    console.log(Math.abs(diffInYear.getUTCFullYear() - 1970));
    return Math.abs(diffInYear.getUTCFullYear() - 1970) >= 18 ? true : false;
  };

  const handleChange = (e) => {
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 40) {
        toast("Name can not be more than 40 character!");
        return;
      }
      const value = regex.test(e.target.value);
      if (!value) {
        toast("Numbers and special characters are not allowed");
        return;
      } else {
        setName(e.target.value);
      }
    }
    if (e.target.name === "phone") {
      const value = regexPhone.test(e.target.value);
      if (!value) {
        toast(
          "Alphabets, spaces and special characters except '+' are not allowed"
        );
        return;
      } else {
        setPhoneNumber(e.target.value);
      }
    }
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setDob(null);
    setGender("");
    setRole(() => {
      return currentUser.role === "SUPER_ADMIN"
        ? isTenant
          ? "ADMIN"
          : "SUPER_ADMIN"
        : null;
    });
    setPhoneNumber("");
    setPassword("");
    setCompanyName("");
  };
  const validateYearOfBirth = (year) => {
    const currentYear = new Date(year).getFullYear();

    if (currentYear < 1900) {
      return true;
    } else {
      return false;
    }
  };
  const handleCreate = async () => {
    const flag = validatePhone(phoneNumber);
    if (!validate(name)) {
      toast("Name can not be empty");
      hitApi = false;
    }
    const emailRegex =
      // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,7})*$/;

    if (email && !emailRegex.test(email)) {
      toast("Please enter a valid email address.");
      hitApi = false;
      return;
    }
    if (!validate(email)) {
      toast("Email can not be empty");
      hitApi = false;
    }
    if (!validate(dob)) {
      toast("Date of Birth can not be empty");
      hitApi = false;
    }
    if (dob && !isValidDateFormat(dob)) {
      toast.warn(
        "Invalid date format. Please enter the Date of Birth in the format mmm/dd/yyyy(e.g., Jan/01/2000)."
      );
      hitApi = false;
      return;
    }
    if (validateYearOfBirth(dob)) {
      toast.warn("Year of birth cannot be less than 1900.");
      return;
    }
    if (!validateAge(dob)) {
      toast("User must be 18 years old");
      hitApi = false;
    }
    if (flag === "Up") {
      toast(
        'No alphabets, spaces or special characters except "+" is allowed in phone number'
      );
      hitApi = false;
    }
    if (flag === "Low") {
      toast("Invalid phone number length");
      hitApi = false;
    }
    if (!validate(gender)) {
      toast("Gender is not selected");
      hitApi = false;
    }
    if (!validate(role)) {
      toast("Role can not be empty");
      hitApi = false;
    }
    if (!validate(phoneNumber)) {
      toast("Phone Number can not be empty");
      hitApi = false;
    }
    if (!validate(password)) {
      toast("Password can not be empty");
      hitApi = false;
    }
    if (
      currentUser?.role === "SUPER_ADMIN" &&
      !validate(companyName) &&
      !isTenant
    ) {
      toast("Company Name can not be empty");
      hitApi = false;
      return;
    }
    if (
      validate(name) &&
      validate(email) &&
      validate(dob) &&
      validateAge(dob) &&
      validate(gender) &&
      validate(role) &&
      validate(phoneNumber) &&
      validatePhone(phoneNumber) !== "Up" &&
      validatePhone(phoneNumber) !== "Low" &&
      validate(password)
    ) {
      hitApi = true;
    }
    try {
      if (hitApi) {
        setSaveButton(true);
        setLoaderDialog(true);
      }
      // setTimeout(() => {
      //   setSaveButton(false);
      // }, 5000);
      let response = null;
      if (currentUser?.role === "SUPER_ADMIN" && hitApi) {
        response = await addTenant(
          isTenant
            ? {
                email: email?.toLowerCase()?.trim(),
                phone: phoneNumber,
                password,
                name: name?.trim(),
                gender,
                dob,
                role,
              }
            : {
                email: email?.toLowerCase().trim(),
                phone: phoneNumber,
                password,
                name: name?.trim(),
                gender,
                dob,
                role,
                company_name: companyName,
              },
          isTenant
        );
      } else {
        response = hitApi
          ? await addUser({
              name: name?.trim(),
              email: email?.toLowerCase().trim(),
              dob,
              gender,
              role,
              phone: phoneNumber,
              password,
            })
          : null;
      }

      if (response?.data?.message === "User with email already exists!") {
        setSaveButton(false);
        toast("User with email already exists!");
        // resetState();
        setUsers([]);
        setPageCount(1);
        console.log(users);
        console.log("User created");
        // handleClose();
        setLoaderDialog(false);
        if (currentUser?.role === "SUPER_ADMIN") {
          fetchUsersForSuperAdmin();
        } else {
          fetchUsers();
        }
      }
      if (response?.data?.message === "User created successfully!") {
        setSaveButton(false);
        toast("User created successfully");
        resetState();
        setUsers([]);
        setPageCount(1);
        console.log(users);
        console.log("User created");
        handleClose();
        setLoaderDialog(false);
        if (currentUser?.role === "SUPER_ADMIN") {
          fetchUsersForSuperAdmin();
        } else {
          fetchUsers();
        }
      }
      // if (response.data.status === true) {
      //   toast("User created successfully");
      //   setUsers([]);
      //   setPageCount(1);
      //   fetchUsers();
      //   console.log(users);
      //   console.log("User created");
      //   handleClose();
      // }
    } catch (error) {
      setLoaderDialog(false);
      setSaveButton(false);
      toast(
        error.response?.data?.data
          ? error.response?.data?.data[0]
          : error.response?.data?.message
      );
    }
  };
  function fetchUsers() {
    if (!typeText) {
      getUsers(pageCount, true)
        .then((response) => {
          if (response.data.status) {
            console.log("response", response);
            setUsers((prevState) => [...prevState, ...response.data.data]);
            setCount(response.data.data.length);
            setIsLoading(false);
          } else {
            navigate(`/unauthorized`);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });
    }
  }

  async function fetchUsersForSuperAdmin() {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { success, responseObject } = {},
          data: usersData,
          status,
        } = {},
      } = await getTenants(isTenant);
      if (success && responseObject && responseObject?.length) {
        setUsers([...responseObject]);
        setCount(responseObject?.length);
      } else if (status && usersData && usersData?.length) {
        setUsers([...usersData]);
        setCount(usersData?.length);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await getRolesName();
      if (response.data.status) {
        setRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.role !== "SUPER_ADMIN") {
      fetchUsers();
      fetchRoles();
    } else if (!typeText && currentUser && currentUser.role === "SUPER_ADMIN") {
      setRoles([
        isTenant
          ? {
              _id: "639189df043c4cf33a6afdd2",
              roleName: "ADMIN",
            }
          : {
              _id: "639189df043c4cf33a6afdd2",
              roleName: "SUPER_ADMIN",
            },
      ]);
      setRole(isTenant ? "ADMIN" : "SUPER_ADMIN");
      fetchUsersForSuperAdmin();
    }

    // if (isMailSent) {
    //   toast("Mail sent Sucessfully")
    // } else {
    //   toast("Something went wrong")
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pageCount, typeText, currentUser]);
  }, [pageCount, typeText]);

  const isUserNotFound = filteredUsers.length === 0;
  const onChange = (e) => {
    setsearchTerm(e.target.value);
    setPage(0);
  };

  console.log("*********************", page);

  //compose mail dialog
  const handleEmailDialogOpen = () => {
    setOpen(true);
  };
  const handleClickMail = (email) => {
    //check email password
    // if (currentUser.email_password === "") {
    //   handleSetPasswordAgree();
    // } else {
    //   setMailTo(email);
    //   handleEmailDialogOpen();
    // }
    if (emailPassword === "") {
      handleSetPasswordAgree();
    } else {
      setMailTo(email);
      handleEmailDialogOpen();
    }
  };

  const removeUnderScore = (role) => {
    if (role.includes("_")) {
      console.log(role);
      const temp = role.replace("_", " ");
      console.log(temp);
      return temp;
    }
    return role;
  };

  // console.log("authcontext", currentUser);

  const [agreeSetPasswordDialog, setAgreeSetPasswordDialog] =
    React.useState(false);

  const handleSetPasswordAgree = () => {
    setAgreeSetPasswordDialog(true);
  };

  const handlePasswordAgree = () => {
    handleClose();
    toggleShowMailConsentPopup();
  };

  //monitor state var emailpassword
  useEffect(() => {
    console.log("Email Password", emailPassword);
  }, [emailPassword]);

  const indexDbpassword = async () => {
    const getpass = await getPassword();
    if (getpass !== undefined && getpass !== null && getpass !== "") {
      setEmailPassword(getpass);
      console.log("UseEffect triggered");
    }
  };

  // getpassword from IndexedDB
  useEffect(() => {
    indexDbpassword();
  }, []);

  useEffect(() => {
    console.log("Context Mail Click", contextMailClick);
    if (emailPassword !== "") {
      console.log("Email Password Not Empty");
      if (contextMailClick) {
        handleClickMail(contextMailName);
      }
    }
    if (emailPassword === "") {
      console.log("Email Password Not Empty");
    }
  }, []);

  return (
    <Page>
      <Container>
        <EmailDialog
          open={open}
          // setIsMailSent={setIsMailSent}
          setOpen={setOpen}
          mailTo={mailTo}
          setContextMailClick={setContextMailClick}
        />
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={5} variant="outlined">
              <CardContent>
                {/* <Grid container spacing={0}>
                  <Grid
                    item
                    xs={9}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom="false"
                      color="primary"
                      sx={{ marginBottom: 0 }}
                    >
                      USERS
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={handleClickOpen}
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid> */}
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <SearchUser
                      users={users}
                      setUsers={setUsers}
                      setCount={setCount}
                      column={column}
                      setColumn={setColumn}
                      typeText={typeText}
                      setTypeText={setTypeText}
                      onChange={onChange}
                      page={page}
                      setPage={setPage}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      setIsLoading={setIsLoading}
                      currentUser={currentUser}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      sx={{ maxHeight: "40px" }}
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={handleClickOpen}
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              variant="outlined"
              sx={{ paddingLeft: 2, paddingRight: 2 }}
            >
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={
                        currentUser?.role !== "SUPER_ADMIN"
                          ? TABLE_HEAD
                          : isTenant
                          ? TABLE_HEAD
                          : SUPER_ADMIN_TABLE_HEAD
                      }
                      rowCount={count}
                      // numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((val) => {
                          if (searchTerm == "") {
                            return val;
                          } else if (
                            val.name
                              .toLowerCase()
                              .includes(searchTerm.toLocaleLowerCase())
                          ) {
                            return val;
                          } else if (
                            val.phone
                              .toLowerCase()
                              .includes(searchTerm.toLocaleLowerCase())
                          ) {
                            return val;
                          }
                        })
                        .map((user, index) => {
                          if (currentUser?.role !== "SUPER_ADMIN") {
                            const {
                              _id,
                              name,
                              email,
                              role,
                              phone,
                              active_status,
                            } = user;
                            const isItemSelected =
                              selected.indexOf(name) !== -1;

                            return (
                              <TableRow
                                sx={{ cursor: "pointer" }}
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                onClick={() => {
                                  // handleClick(_id);
                                  console.log("Table row index", index);
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  onClick={() => {
                                    console.log("Table cell Name index", index);
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      marginLeft="20px"
                                    >
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell
                                  onClick={() => {
                                    console.log(
                                      "Table cell email index",
                                      index,
                                      email
                                    );
                                  }}
                                >
                                  {email}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() => {
                                    console.log("Table cell role index", index);
                                  }}
                                >
                                  {role === "DELIVERY_LEAD"
                                    ? "DELIVERY LEAD"
                                    : role}
                                </TableCell>
                                <TableCell align="left">{phone}</TableCell>
                                <TableCell align="left">
                                  {active_status ? (
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      ACTIVE
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      INACTIVE
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() => {
                                    console.log(
                                      "Table cell action index",
                                      index,
                                      _id
                                    );
                                  }}
                                >
                                  <ContextMenu
                                    handleClickMenu={handleClickMenu}
                                    handleCloseMenu={handleCloseMenu}
                                    handleClick={handleClick}
                                    handleDelete={handleDelete}
                                    handleEnable={handleEnable}
                                    handleClickMail={handleClickMail}
                                    index={index}
                                    _id={_id}
                                    active_status={active_status}
                                    email={email}
                                    setContextMailClick={setContextMailClick}
                                    setContextMailName={setContextMailName}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            if (isTenant) {
                              const {
                                _id,
                                name,
                                email,
                                role,
                                phone,
                                active_status,
                                dob,
                                gender,
                              } = user;
                              const isItemSelected =
                                selected.indexOf(name) !== -1;

                              return (
                                <TableRow
                                  sx={{ cursor: "pointer" }}
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                  onClick={() => {
                                    // handleClick(_id);
                                    console.log("Table row index", index);
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    onClick={() => {
                                      console.log(
                                        "Table cell Name index",
                                        index
                                      );
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                                      <Typography
                                        variant="subtitle2"
                                        noWrap
                                        marginLeft="20px"
                                      >
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    onClick={() => {
                                      console.log(
                                        "Table cell email index",
                                        index,
                                        email
                                      );
                                    }}
                                  >
                                    {email}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      console.log(
                                        "Table cell role index",
                                        index
                                      );
                                    }}
                                  >
                                    {role === "DELIVERY_LEAD"
                                      ? "DELIVERY LEAD"
                                      : role}
                                  </TableCell>
                                  <TableCell align="left">{phone}</TableCell>
                                  <TableCell align="left">
                                    {active_status ? (
                                      <span style={{ color: "green" }}>
                                        {" "}
                                        ACTIVE
                                      </span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        INACTIVE
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align={"left"}
                                    onClick={() => {
                                      console.log(
                                        "Table cell action index",
                                        index,
                                        _id
                                      );
                                    }}
                                  >
                                    <ContextMenu
                                      handleClickMenu={handleClickMenu}
                                      handleCloseMenu={handleCloseMenu}
                                      handleClick={handleClick}
                                      handleDelete={handleDelete}
                                      handleEnable={handleEnable}
                                      handleClickMail={handleClickMail}
                                      index={index}
                                      _id={_id}
                                      active_status={active_status}
                                      email={email}
                                      setContextMailClick={setContextMailClick}
                                      setContextMailName={setContextMailName}
                                      userDetails={{
                                        name,
                                        email,
                                        phone,
                                        role,
                                        dob,
                                        gender,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            } else {
                              const {
                                _id,
                                name,
                                email,
                                role,
                                phone,
                                company_name,
                                database_name,
                                active_status,
                                gender,
                                dob,
                              } = user;
                              const isItemSelected =
                                selected.indexOf(name) !== -1;

                              return (
                                <TableRow
                                  sx={{ cursor: "pointer" }}
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                  onClick={() => {
                                    // handleClick(_id);
                                    console.log("Table row index", index);
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    onClick={() => {
                                      console.log(
                                        "Table cell Name index",
                                        index
                                      );
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                                      <Typography
                                        variant="subtitle2"
                                        noWrap
                                        marginLeft="20px"
                                      >
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    onClick={() => {
                                      console.log(
                                        "Table cell email index",
                                        index,
                                        email
                                      );
                                    }}
                                  >
                                    {email}
                                  </TableCell>
                                  <TableCell align="left">{phone}</TableCell>

                                  <TableCell align="left">
                                    {company_name}
                                  </TableCell>

                                  <TableCell align="left">
                                    {database_name}
                                  </TableCell>

                                  <TableCell align="left">
                                    {active_status ? (
                                      <span style={{ color: "green" }}>
                                        {" "}
                                        ACTIVE
                                      </span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        INACTIVE
                                      </span>
                                    )}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    onClick={() => {
                                      console.log(
                                        "Table cell action index",
                                        index,
                                        _id
                                      );
                                    }}
                                  >
                                    <ContextMenu
                                      handleClickMenu={handleClickMenu}
                                      handleCloseMenu={handleCloseMenu}
                                      handleClick={handleClick}
                                      handleDelete={handleDelete}
                                      handleEnable={handleEnable}
                                      handleClickMail={handleClickMail}
                                      index={index}
                                      _id={_id}
                                      active_status={active_status}
                                      email={email}
                                      setContextMailClick={setContextMailClick}
                                      setContextMailName={setContextMailName}
                                      userDetails={{
                                        name,
                                        email,
                                        phone,
                                        // company_name,
                                        // database_name,
                                        // active_status,
                                        role,
                                        dob,
                                        gender,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          }
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isLoading || isUserNotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            {isLoading ? (
                              <CircularProgress />
                            ) : (
                              <SearchNotFound searchQuery={filterName} />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>

          {/* <Box
            onClick={handleClickOpen}
            sx={{ position: "fixed", bottom: 16, right: 16 }}
          >
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Box> */}
        </Grid>
        <Dialog open={openAddUser} onClose={handleClose}>
          <DialogTitle textAlign="center">Add a user</DialogTitle>
          <DialogContent>
            <Grid container spacing={2.5}>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TextField
                  required
                  id="outlined-basic-name"
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-basic-email"
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-basic-password"
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel id="demo-radio-buttons-group-label" required>
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setGender(
                      e.target.value === "male"
                        ? "M"
                        : e.target.value === "female"
                        ? "F"
                        : "O"
                    )
                  }
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                {/* <FormLabel id="outlined-basic-name" required>Date of Birth</FormLabel>
                <TextField
                  required
                  sx={{ marginTop: "10px" }}
                  id="outlined-basic-name"
                  fullWidth
                  variant="outlined"
                  type="date"
                  onChange={(e) => setDob(e.target.value)}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DesktopDatePicker
                    required
                    fullWidth
                    label="Date of Birth *"
                    inputFormat="MMM/dd/yyyy"
                    // minDate={}
                    // minDate={}
                    value={dob}
                    name="dob"
                    onChange={(newValue) => {
                      setDob(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="outlined-basic-name"
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Phone Number"
                  placeholder="Ex: +919080706050"
                  name="phone"
                  onChange={handleChange}
                  value={phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <FormLabel id="demo-radio-buttons-group-label" required>Role</FormLabel>
                <RadioGroup
                  fullWidth
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => handleSetRole(e)}
                >
                  <FormControlLabel
                    value="ADMIN"
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value="SALES"
                    control={<Radio />}
                    label="Sales"
                  />
                  <FormControlLabel
                    value="DELIVERY_LEAD"
                    control={<Radio />}
                    label="Delivery Lead"
                  />
                  <FormControlLabel
                    value="MEMBER"
                    control={<Radio />}
                    label="Member"
                  />
                </RadioGroup> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-country-select-label">Role</InputLabel>
                  <Select
                    // disabled={toEdit}
                    // inputProps={{ readOnly: true }}
                    // inputProps={
                    //   isEditPage ? { readOnly: inputProps } : fromLeadViewPage ? { readOnly: true } : inputProps
                    // }
                    labelId="demo-country-select-label"
                    id="demo-country-select"
                    label="Role"
                    name="role"
                    value={role}
                    onChange={(e) => handleSetRole(e)}
                    readOnly={currentUser?.role === "SUPER_ADMIN"}
                  >
                    {roles
                      .filter((role) => {
                        if (currentUserRole === "ADMIN") {
                          if (role.roleName !== "SUPER_ADMIN") {
                            return role;
                          }
                        } else {
                          return role;
                        }
                      })
                      .map((role, index) => {
                        return (
                          <MenuItem key={index} value={role.roleName}>
                            {removeUnderScore(role.roleName)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              {currentUser?.role === "SUPER_ADMIN" ? (
                !isTenant ? (
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="companyName"
                      value={companyName}
                      type="text"
                      variant="outlined"
                      label="Company Name"
                      placeholder="Company Name"
                      fullWidth
                      onChange={({ target: { value } = {} }) => {
                        setCompanyName(value);
                      }}
                    />
                  </Grid>
                ) : null
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              CANCEL
            </Button>
            <Button disabled={saveButton} onClick={handleCreate}>
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={loaderDialog}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
            }}
          >
            Loading... Please Wait
          </DialogActions>
        </Dialog>
        <div>
          <Dialog
            open={deletePopupOpen.state}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Disable confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you confirm to disabled the user {}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                DISAGREE
              </Button>
              <Button onClick={deletePopupOpen.onSuccess} autoFocus>
                AGREE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Dialog
          open={enablePopupOpen.state}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Enable confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you confirm to enable the user {}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              DISAGREE
            </Button>
            <Button
              // onClick={() => {
              //   console.log("active_status 90", enablePopupOpen);
              //   return enablePopupOpen.onSucess();
              // }}
              onClick={enablePopupOpen.onSuccess}
              autoFocus
            >
              AGREE
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Dialog open={agreeSetPasswordDialog} keepMounted onClose={handleClose}>
        <DialogTitle>{"Email Password Not Set"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You haven't set your email's password. Click on AGREE if you want to
            set it by now.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            DISAGREE
          </Button>
          <Button onClick={handlePasswordAgree}>AGREE</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
