import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";

import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth.context";
import { useDynamicContactTitle } from "./Contacts";

export default function SplitButton({
  navigate,
  setFileUploadDialog,
  handleLinkedInButtonClick,
}) {
  const { currentUser } = React.useContext(AuthContext);
  const contactDynamicTitle = useDynamicContactTitle();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    if (currentUser.role === "DELIVERY_LEAD") {
      setSelectedIndex(0);
    }
  }, [currentUser.role]);

  const options = [
    `+ Create ${contactDynamicTitle?.displayName.singularName}`,
    `Upload Visiting Card`,
    `From LinkedIn Profile Url`,
  ];
  if (currentUser.role === "DELIVERY_LEAD") {
    options.splice(0, 1);
  }

  const handleClick = () => {
    // console.log("ytuf67");

    if (
      options[selectedIndex] ===
      `+ Create ${contactDynamicTitle?.displayName.singularName}`
    ) {
      console.log("ytuf67");
      navigate(`/contacts/createContact`);
      return;
    }

    if (options[selectedIndex] === `Upload Visiting Card`) {
      setFileUploadDialog((prev) => !prev);
    }

    if (options[selectedIndex] === `From LinkedIn Profile Url`) {
      handleLinkedInButtonClick();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          sx={{ minWidth: "200px", maxHeight: "40px" }}
          onClick={handleClick}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          sx={{ minWidth: "200px", maxHeight: "40px" }}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu" autoFocusItem>
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </React.Fragment>
  );
}
