import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Grid, Typography, Box } from "@mui/material";

// const data = [
//   {
//     id: "japan",
//     color: "hsl(97, 70%, 50%)",
//     data: [
//       {
//         x: "plane",
//         y: 32,
//       },
//       {
//         x: "helicopter",
//         y: 1,
//       },
//       {
//         x: "boat",
//         y: 8,
//       },
//       {
//         x: "train",
//         y: 194,
//       },
//       {
//         x: "subway",
//         y: 274,
//       },
//       {
//         x: "bus",
//         y: 50,
//       },
//       {
//         x: "car",
//         y: 176,
//       },
//       {
//         x: "moto",
//         y: 287,
//       },
//       {
//         x: "bicycle",
//         y: 181,
//       },
//       {
//         x: "horse",
//         y: 178,
//       },
//       {
//         x: "skateboard",
//         y: 237,
//       },
//       {
//         x: "others",
//         y: 279,
//       },
//     ],
//   },
//   {
//     id: "france",
//     color: "hsl(264, 70%, 50%)",
//     data: [
//       {
//         x: "plane",
//         y: 28,
//       },
//       {
//         x: "helicopter",
//         y: 91,
//       },
//       {
//         x: "boat",
//         y: 95,
//       },
//       {
//         x: "train",
//         y: 55,
//       },
//       {
//         x: "subway",
//         y: 115,
//       },
//       {
//         x: "bus",
//         y: 298,
//       },
//       {
//         x: "car",
//         y: 7,
//       },
//       {
//         x: "moto",
//         y: 149,
//       },
//       {
//         x: "bicycle",
//         y: 95,
//       },
//       {
//         x: "horse",
//         y: 215,
//       },
//       {
//         x: "skateboard",
//         y: 252,
//       },
//       {
//         x: "others",
//         y: 15,
//       },
//     ],
//   },
//   //   {
//   //     id: "us",
//   //     color: "hsl(122, 70%, 50%)",
//   //     data: [
//   //       {
//   //         x: "plane",
//   //         y: 129,
//   //       },
//   //       {
//   //         x: "helicopter",
//   //         y: 25,
//   //       },
//   //       {
//   //         x: "boat",
//   //         y: 43,
//   //       },
//   //       {
//   //         x: "train",
//   //         y: 124,
//   //       },
//   //       {
//   //         x: "subway",
//   //         y: 237,
//   //       },
//   //       {
//   //         x: "bus",
//   //         y: 142,
//   //       },
//   //       {
//   //         x: "car",
//   //         y: 137,
//   //       },
//   //       {
//   //         x: "moto",
//   //         y: 227,
//   //       },
//   //       {
//   //         x: "bicycle",
//   //         y: 30,
//   //       },
//   //       {
//   //         x: "horse",
//   //         y: 226,
//   //       },
//   //       {
//   //         x: "skateboard",
//   //         y: 224,
//   //       },
//   //       {
//   //         x: "others",
//   //         y: 33,
//   //       },
//   //     ],
//   //   },
//   //   {
//   //     id: "germany",
//   //     color: "hsl(20, 70%, 50%)",
//   //     data: [
//   //       {
//   //         x: "plane",
//   //         y: 200,
//   //       },
//   //       {
//   //         x: "helicopter",
//   //         y: 288,
//   //       },
//   //       {
//   //         x: "boat",
//   //         y: 253,
//   //       },
//   //       {
//   //         x: "train",
//   //         y: 161,
//   //       },
//   //       {
//   //         x: "subway",
//   //         y: 213,
//   //       },
//   //       {
//   //         x: "bus",
//   //         y: 132,
//   //       },
//   //       {
//   //         x: "car",
//   //         y: 72,
//   //       },
//   //       {
//   //         x: "moto",
//   //         y: 41,
//   //       },
//   //       {
//   //         x: "bicycle",
//   //         y: 249,
//   //       },
//   //       {
//   //         x: "horse",
//   //         y: 263,
//   //       },
//   //       {
//   //         x: "skateboard",
//   //         y: 88,
//   //       },
//   //       {
//   //         x: "others",
//   //         y: 168,
//   //       },
//   //     ],
//   //   },
//   //   {
//   //     id: "norway",
//   //     color: "hsl(309, 70%, 50%)",
//   //     data: [
//   //       {
//   //         x: "plane",
//   //         y: 75,
//   //       },
//   //       {
//   //         x: "helicopter",
//   //         y: 60,
//   //       },
//   //       {
//   //         x: "boat",
//   //         y: 213,
//   //       },
//   //       {
//   //         x: "train",
//   //         y: 161,
//   //       },
//   //       {
//   //         x: "subway",
//   //         y: 268,
//   //       },
//   //       {
//   //         x: "bus",
//   //         y: 130,
//   //       },
//   //       {
//   //         x: "car",
//   //         y: 73,
//   //       },
//   //       {
//   //         x: "moto",
//   //         y: 92,
//   //       },
//   //       {
//   //         x: "bicycle",
//   //         y: 88,
//   //       },
//   //       {
//   //         x: "horse",
//   //         y: 148,
//   //       },
//   //       {
//   //         x: "skateboard",
//   //         y: 215,
//   //       },
//   //       {
//   //         x: "others",
//   //         y: 234,
//   //       },
//   //     ],
//   //   },
// ];
// const data = [
//   {
//     id: "UL ALPHA",
//     color: "hsl(97, 70%, 50%)",
//     data: [
//       {
//         x: "Total",
//         y: 46,
//       },
//       {
//         x: "lead",
//         y: 33,
//       },
//       {
//         x: "prospect",
//         y: 3,
//       },
//       {
//         x: "oppurtunity",
//         y: 2,
//       },
//       {
//         x: "close",
//         y: 8,
//       },
//       {
//         x: "Drop Count",
//         y: 5,
//       },
//     ],
//   },
// ];
const Nivoline = ({
  reportFigures,
  reportData,
  isCompared,
  salesOwnerName,
  status,
  countries,
  selectOwnerList,
  OwnersNameList,

  primaryComparetor,
  salesOwnerFigure,
}) => {
  // let maxValue;
  const [maxValues, setMaxValues] = useState({});
  let Total = Object.values(reportFigures).reduce((acc, curr) => acc + curr, 0);
  // reportFigures.Total = reportData.length;

  console.log(reportFigures, "modi");

  useEffect(() => {
    if (salesOwnerFigure && salesOwnerFigure.length > 0) {
      const calculateMaxValues = () => {
        const updatedMaxValues = {};
        salesOwnerFigure.forEach(({ reportFigures }) => {
          Object.keys(reportFigures).forEach((key) => {
            if (
              !(key in updatedMaxValues) ||
              reportFigures[key] > updatedMaxValues[key]
            ) {
              updatedMaxValues[key] = reportFigures[key];
            }
          });
        });
        return updatedMaxValues;
      };

      setMaxValues(() => calculateMaxValues());
    }
  }, [salesOwnerFigure]);

  const data = [
    {
      id: "Status",
      color: "hsl(264, 70%, 50%)",
      data: Object.keys(reportFigures).map((key) => ({
        x: key.charAt(0).toUpperCase() + key.slice(1),
        y: reportFigures[key],
      })),
    },
  ];
  const renderStatus = (listArray) => {
    if (listArray.length > 4) {
      if (listArray.length === 5) {
        const firstThree = listArray.slice(0, 3);

        const additionalCount = listArray.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = listArray.slice(0, 4);

      const additionalCount = listArray.length - 4;

      return `${firstThree.join(",")} and ${additionalCount} others`;
    } else {
      // return listArray.join(", ");
      const listCopy = [...listArray];
      const lastElement = listCopy.pop();
      console.log(`${listCopy.join(", ")} and ${lastElement}`, "myelseee");
      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };
  const renderSalesOwners = (userIds, userInfo) => {
    const filteredUsers = userInfo.filter((user) => userIds.includes(user._id));

    const userNames = filteredUsers.map((user) => user.name);

    if (userNames.length > 4) {
      if (userNames.length === 5) {
        const firstThree = userNames.slice(0, 3);

        const additionalCount = userNames.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = userNames.slice(0, 4);
      const additionalCount = userNames.length - 4;
      return `${firstThree.join(", ")} and ${additionalCount} others`;
    } else {
      // return userNames.join(", ");
      const listCopy = [...userNames];
      const lastElement = listCopy.pop();

      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };

  return (
    <>
      {isCompared && (
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column" }}
          spacing={0}
        >
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Status"
                  : primaryComparetor === "Country"
                  ? "Sales Owner"
                  : "Country"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(status)
                : primaryComparetor === "Country"
                ? renderSalesOwners(selectOwnerList, OwnersNameList)
                : renderStatus(countries)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Country"
                  : primaryComparetor === "Country"
                  ? "Status"
                  : "Sales Owner"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(countries)
                : primaryComparetor === "Country"
                ? renderStatus(status)
                : renderSalesOwners(selectOwnerList, OwnersNameList)}
            </Typography>
          </Box>
        </Grid>
      )}
      <div
        style={{
          width: "100%",
          height: "50vh",
          margin: "auto",
        }}
      >
        <div style={{ width: "100%", height: "100%", marginBottom: "-4px" }}>
          {maxValues &&
          salesOwnerFigure &&
          salesOwnerFigure.length &&
          isCompared ? (
            <ResponsiveLine
              data={data}
              margin={{
                top: 50,
                right: !isCompared ? 110 : 97,
                // right: 110,
                bottom: 50,
                left: 60,
              }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                // max: "auto",
                max: Math.max(...Object.values(maxValues)),
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              theme={{
                background: "#ffffff",
                text: {
                  fontSize: 11,
                  fill: "#cf0c0c",
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 15,
                      fill: "#1878f7",
                      outlineWidth: 4,
                      outlineColor: "transparent",
                    },
                  },
                  ticks: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#dddddd",
                    strokeWidth: 1,
                  },
                },
                legends: {
                  title: {
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                  text: {
                    fontSize: 11,
                    fill: "#333333",
                    outlineWidth: 0,
                    outlineColor: "transparent",
                  },
                },
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Lead",
                legendOffset: 40,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -45,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <ResponsiveLine
              data={data}
              margin={{
                top: 50,
                right: !isCompared ? 110 : 80,
                // right: 110,
                bottom: 50,
                left: 60,
              }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                // max: Math.max(...Object.values(maxValues)),
                stacked: true,
                reverse: false,
              }}
              theme={{
                background: "#ffffff",
                text: {
                  fontSize: 11,
                  fill: "#cf0c0c",
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 15,
                      fill: "#1878f7",
                      outlineWidth: 4,
                      outlineColor: "transparent",
                    },
                  },
                  ticks: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#dddddd",
                    strokeWidth: 1,
                  },
                },
                legends: {
                  title: {
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                  text: {
                    fontSize: 11,
                    fill: "#333333",
                    outlineWidth: 0,
                    outlineColor: "transparent",
                  },
                },
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Lead",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0,
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          )}
        </div>
        {/* <Typography textAlign="center" fontWeight="300" color="#FF5733">
        <b>Total</b>: <b>{Total || 0}</b>
      </Typography> */}
        {salesOwnerName ? (
          <Grid
            container
            sx={{
              marginBottom: "30px",
            }}
            spacing={1}
          >
            <Grid xs={10} item>
              {" "}
              <Typography textAlign="start" fontWeight="300" color="#FF5733">
                <b>Name</b>: <b>{salesOwnerName}</b>
              </Typography>
            </Grid>
            <Grid xs={2} item>
              {" "}
              <Typography textAlign="end" fontWeight="300" color="#FF5733">
                <b>Total</b>: <b>{Total || 0}</b>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography textAlign="center" fontWeight="300" color="#FF5733">
            <b>Total</b>: <b>{Total || 0}</b>
          </Typography>
        )}
      </div>
    </>
  );
};

export default Nivoline;
