import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./FileUpload.css";
import axios from "axios";
import { toast } from "react-toastify";

const FileUpload = ({
  files,
  setFiles,
  removeFile,
  checked,
  setChecked,
  openProjectStatusDialouge,
  signedContractFiles,
  setSignedContractFiles,
  isFromSacnDoc,
}) => {
  const maxSizeInBytes = 10 * 1024 * 1024;
  const [updateKey, setUpdateKey] = useState(0);

  const validateImage = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error("No file selected."));
        return;
      }

      // Create an image element to read dimensions
      const img = new Image();
      img.onload = () => {
        // Check image dimensions

        console.log("Fileopkiiu", "w", img.width, "h", img.height);
        if (img.width >= 500 && img.height >= 290) {
          resolve(file);
        } else {
          reject(
            new Error(`${file.name} resolution should be minimum  (500x290).`)
          );
        }
      };
      img.onerror = () => {
        reject(new Error("Failed to load the image."));
      };

      // Read the image
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadHandler = (event) => {
    // const file = event.target.files[0];
    console.log("Event files", event.target.files);
    const file = Array.from(event.target.files);
    let filterFiles = file.filter((item, index) => {
      if (item.size > maxSizeInBytes) {
        toast.warn(`${item.name} size more than 10 MB not allowed`);
        return;
      } else {
        return item;
      }
    });
    console.log("Fileopkiiu", file, filterFiles);

    if (isFromSacnDoc) {
      validateImage(filterFiles[0])
        .then((validFile) => {
          // File is valid, perform further actions
          console.log("Fileopkiiu0:", validFile);
          setFiles((prev) => {
            return [...prev, ...filterFiles];
          });
          // You can upload the file or perform additional operations here
        })
        .catch((error) => {
          // Handle validation errors
          console.error("Validation Error:", error.message);
          // Display error message to the user
          toast.warn(error.message);
        });
    } else {
      setFiles((prev) => {
        return [...prev, ...filterFiles];
      });
    }
  };
  const uploadHandlerForSignedContract = (event) => {
    // const file = event.target.files[0];

    const file = Array.from(event.target.files);
    let filterFiles = file.filter((item, index) => {
      if (item.size > maxSizeInBytes) {
        toast.warn(`${item.name} size more than 10 MB not allowed`);
        return;
      } else {
        return item;
      }
    });
    console.log("signedContractFiles u", event.target.files, filterFiles);
    // console.log("File", file);

    setSignedContractFiles(() => {
      return [...filterFiles];
    });
    setUpdateKey(updateKey + 1);
  };

  return (
    <>
      {openProjectStatusDialouge ? (
        <div className="file-card">
          <div className="file-inputs">
            <input
              key={updateKey}
              type="file"
              onChange={uploadHandlerForSignedContract}
            />
            {/* <input type="file" name="files[]" multiple /> */}
            <button>
              <i>
                <FontAwesomeIcon icon={faPlus} />
              </i>
              Select files
            </button>
          </div>

          {/* <p className="main">Supported files</p> */}
          {/* <p className="info">PDF, JPG, PNG</p> */}
        </div>
      ) : (
        <div className="file-card">
          <div className="file-inputs">
            <input
              type="file"
              multiple={isFromSacnDoc ? null : "multiple"}
              onChange={uploadHandler}
            />
            <button>
              <i>
                <FontAwesomeIcon icon={faPlus} />
              </i>
              Select files
            </button>
          </div>

          {/* <p className="main">Supported files</p> */}
          {/* <p className="info">PDF, JPG, PNG</p> */}
        </div>
      )}
    </>
  );
};

export default FileUpload;
