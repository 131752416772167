import React, { useCallback, useContext, useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  TextField,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  breadcrumbsClasses,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getCountries } from "../../services/common.service.js";
import {
  createContact,
  UpdateProject,
  getLeads,
} from "../../services/contacts.service.js";
import Box from "@mui/material/Box";
import { getUsersByRoles } from "../../services/users.service.js";
import {
  getContactsForDrop,
  getContact,
} from "../../services/leads.service.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { grey } from "@mui/material/colors";
import {
  getLeadsForProject,
  getTechStacks,
  updateProject,
} from "../../services/projects.service.js";
import { AuthContext } from "../../context/auth.context.js";
import { options } from "numeral";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads.js";
import { useDynamicContactTitle } from "../../pages/Contacts/Contacts.js";
import { useDynamicProjectTitle } from "../../pages/Projects/Projects.js";
import getAttributesByEntity from "../../services/entity.attribute.services.js";
import EntityAttributeFields from "../Shared/Custom/EntityAttributeFields.js";
import validateUniqueAttributes from "../../utils/customAttributesUtils.js";
import { capitalizedString } from "../../utils/capitalizedString.js";
import SplitButton from "./SplitButton.js";

const ProjectInfo = ({
  setProject,
  project,
  toEdit,
  setToEdit,
  id,
  isProjectEditPage,
  tempProject,
  setTempProject,
  resource,
  setResource,
  contact,
  setContact,
  setTempProjectLead,
  tempProjectLead,
  tempProjectTeam,
  setTempProjectTeam,
  tarf,
  projectId,
  fromCreatePage,
  fromViewPage,
  setIsProjectFetched,
  projectLead,
  setProjectLead,
  customAttributes,
  setCustomAttributes,
  afterCreate,
  setIactionedApiCalled,
}) => {
  console.log(project);
  const contactDynamicTitle = useDynamicContactTitle();
  const leadDynamicTitle = useDynamicLeadTitle();
  const projectDynamicTitel = useDynamicProjectTitle();
  console.log("ProjectObj", project, "ProjectLeadObj", project);
  console.log("resource", resource);
  console.log("tempProjectLead", tempProjectLead);
  console.log("tempProjectTeam", tempProjectTeam);
  console.log("tarf", tarf);

  const [countries, setCountries] = useState([]);

  const [leads, setLeads] = useState([]);
  const [usersPM, setUsersPM] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersDev, setUsersDev] = useState([]);
  const [usersQA, setUsersQA] = useState([]);
  const [userType, setUserType] = useState([]);
  const [display, setdisplay] = useState("");
  // const [countryCode, setCountryCode] = useState("");
  const [value, setValue] = useState("");
  const [devName, setDevName] = React.useState([]);
  const [qaName, setQaName] = React.useState([]);
  const [startline, setStartline] = useState(new Date());
  const [deadline, setDeadline] = useState(new Date());
  const [isStartlineSet, setIsStartlineTest] = useState(false);
  const [counter, setCounter] = useState(0);
  const [techStacks, setTechStacks] = useState([]);
  //   const [intArray, setIntArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [techStackName, setTechStackName] = useState("");
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const [randomKey, setRandomKey] = useState("sdvdf");

  const roles = [
    { name: "Project Manager", value: "PM" },
    { name: "Team Lead", value: "TL" },
    { name: "Developer", value: "DEV" },
    { name: "Quality Assurance", value: "QA" },
  ];
  const techStackNames = [
    "MERN",
    "MEAN",
    "DotNet",
    "DotNet Core",
    "React",
    "Angular",
    "Vue",
    "Laravel",
    "Ruby on Rails",
    "Spring Boot",
    "Mongo",
    "SQL Server",
    "MySQL",
    "Postgres",
  ];
  const navigate = useNavigate();

  let data = true;
  const [techStack, setTechStack] = useState([]);

  const timing = [
    { name: "Full Time", value: "FULL_TIME" },
    { name: "Part Time", value: "PART_TIME" },
  ];

  const [dateValue, setDateValue] = useState([null, null]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (e) => {
    if (
      e.target.name === "title" &&
      e.target.value &&
      e.target.value.length > 80
    ) {
      toast.warning("Title should not be more then 80 characters.");
      return;
    }

    if (
      e.target.name === "specification" &&
      e.target.value &&
      e.target.value.length > 170
    ) {
      toast.warning("Specification should not be more then 170 characters.");
      return;
    }

    if (
      e.target.name === "details" &&
      e.target.value &&
      e.target.value.length > 350
    ) {
      toast.warning("Details should not be more then 350 characters.");
      return;
    }
    console.log(e, project);
    if (isProjectEditPage) {
      setProjectLead((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    if (!isProjectEditPage) {
      setProject((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    if (e.target.name === "leadId") {
      handleSetContact(e.target.value);
    }
  };

  const handleAddTech = () => {
    if (isProjectEditPage) {
      setProjectLead((prevState) => ({
        ...prevState,
        techStack: [...prevState.techStack, techStackName],
      }));
    } else {
      setProject((prevState) => ({
        ...prevState,
        techStack: [...prevState.techStack, techStackName],
      }));
    }
    if (techStackName.length === 0) {
      toast("Techstack cannot be empty");
    } else {
      setTechStackName("");
      handleClose();
    }
  };

  const handleSetContact = (id) => {
    console.log(id);
    setContact("");
    let temp = {};
    leads.map((e) => {
      if (e._id === id) {
        // setContact(e);
        temp = e;
        console.log("e", e);
        setProject((prevState) => ({
          ...prevState,
          techOwner: e.techOwner,
          techStack: e.skills,
        }));
      }
      setContact(temp);
    });
  };

  const handleDeadlineDateChange = (newValue) => {
    console.log("startline", newValue);
    const temp = { ...project };

    if (startline.getTime() <= newValue) {
      setDeadline(newValue);
      temp.timeline.deadline = newValue;
      setProject(temp);
    } else {
      toast("Due date can not be less than or equal to start date");
    }
  };

  const handleStartlineDateChange = (newValue) => {
    console.log("deadline", newValue);
    const temp = { ...project };

    setStartline(newValue);
    temp.timeline.committedOn = newValue;
    temp.timeline.deadline = new Date(
      new Date(newValue).setDate(new Date(newValue).getDate() + 1)
    );
    setProject(temp);
    setIsStartlineTest(true);
  };

  let toastId = null;

  const handleProjectTimeLine = () => {
    const data = Object.keys(project.timeline).reduce((storage, key) => {
      if (
        key === "deadline" &&
        project.timeline[key] !== tempProject.timeline[key]
      ) {
        storage = true;
      }
      return storage;
    }, false);
    if (data) {
      return project.timeline;
    }
    return null;
  };

  const CheckForLeadUpdate = () => {
    const temp = {
      title: "",
      details: "",
      techStack: [],
      specification: "",
    };
    temp.title = projectLead.title;
    temp.details = projectLead.details;
    temp.techStack = projectLead.techStack;
    temp.specification = projectLead.specification;
    // delete temp.techStack;

    const keys = Object.keys(temp);
    const res = {};
    keys.forEach((key) => {
      if (Array.isArray(temp[key])) {
        if (
          JSON.stringify(temp[key]) !== JSON.stringify(tempProjectLead[key])
        ) {
          res[key] = temp[key];
        }
      } else if (temp[key] !== tempProjectLead[key]) {
        res[key] = temp[key];
      } else {
        return null;
      }
    });
    console.log("res", res);
    const updatedAttributes = validateUniqueAttributes(
      project.attributes,
      customAttributes
    );
    if (updatedAttributes.length) {
      res["attributes"] = [...updatedAttributes];
    }

    const validProjectTimeLine = handleProjectTimeLine();
    console.log(validProjectTimeLine);
    if (validProjectTimeLine) {
      res["timeline"] = validProjectTimeLine;
    }

    return res;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const validate = (company) => {
    const fields = [
      "contactId",
      "specification",
      "title",
      "salesOwner",
      "leadId",
      "details",
    ];
    let flag = true;
    Object.keys(company).forEach((ele) => {
      if (fields.includes(ele) && !company[ele]) {
        toast(`${capitalizedString(ele)} can not be empty`);
        flag = false;
      }
    });
    return flag;
  };

  const UpdateProject = async () => {
    console.log(handleProjectTimeLine());
    const temp = { ...project };
    // temp.source = { source: contact.source };
    // delete temp.source._id;
    const resultLead = CheckForLeadUpdate();

    const checkMandatoryFieldStatus = validate(resultLead);
    if (!checkMandatoryFieldStatus) {
      return;
    }
    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }

    if (Object.keys(resultLead).length > 0) {
      resultLead["techStackArray"] = resultLead.techStack;
      delete resultLead.techStack;
      try {
        const response = await updateProject(projectId, {
          ...resultLead,
          attributes: [...customAttributes],
        });
        if (response.data.status) {
          setIactionedApiCalled(true);
          toast(
            `${projectDynamicTitel?.displayName.singularName} details successfully updated`
          );
          setToEdit(true);
          setIsProjectFetched(false);
        }
      } catch (error) {
        console.log(error);
        setToEdit(false);
        if (error.response.data.message) {
          toast(error.response.data.message);
        }
        toast(error.response.data.data[0]);
      }
    } else {
      toast("Nothing to update");
      setToEdit(false);
    }
  };

  const fetchLead = async () => {
    try {
      const response = await getLeadsForProject(fromCreatePage ? 1 : 2);
      if (response.data.status) {
        console.log("response", response);
        setLeads(response.data.data.allLeads);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getUsersByRoles(4);
      if (response.data.status) {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmptySkills = (e) => {
    console.log("---------->", e.target.value);
    if (e.target.value !== "" && e.target.value.trim() !== "") {
      setTechStackName(e.target.value);
    }
  };

  const handleEmptySkillsChip = (val) => {
    val.map((element, index) => {
      if (element.name.length > 0) {
        return element;
      }
    });
  };

  const addNewTechstackAndShowChip = () => {
    console.log("Inside button click", techStackName);
    if (techStackName !== "") {
      if (isProjectEditPage) {
        setProjectLead((prevState) => ({
          ...prevState,
          techStack: [...prevState.techStack, { name: techStackName }],
        }));
      } else {
        setProject((prevState) => ({
          ...prevState,
          techStack: [...prevState.techStack, { name: techStackName }],
        }));
      }
      setTechStackName("");
    }
    handleClose();
  };

  const handleCounter = () => {
    if (resource.length !== 0) {
      resource.map((e, i) => {
        if (e.userId === "" || e.assignedRole === "" || e.contribution === "") {
          console.log("e", e);
          data = false;
        } else {
          data = true;
        }
      });
      console.log("data", data);
      if (data) {
        setResource((prevState) => {
          const temp = [...prevState];
          temp.push({
            userId: "",
            assignedRole: "",
            contribution: "",
          });
          return temp;
        });
      } else {
        toast("Fill in Resource Name, Role and Time");
      }
    } else {
      setResource((prevState) => {
        const temp = [...prevState];
        temp.push({
          userId: "",
          assignedRole: "",
          contribution: "",
        });
        return temp;
      });
    }
  };

  const fetchTechStacks = async () => {
    try {
      const response = await getTechStacks();
      if (response.data.status) {
        setTechStacks(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchContact();
    fetchLead();
    fetchUsers();
    fetchTechStacks();
  }, []);
  console.log("Leads length", leads.length);

  useEffect(() => {
    if (!isProjectEditPage) {
      (async () => {
        try {
          const response = await getAttributesByEntity("Projects");
          if (response.data.status) {
            setCustomAttributes(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [afterCreate]);

  return (
    <Card
      className="child"
      elevation={3}
      variant="outlined"
      sx={{ minWidth: "auto", minHeight: "auto" }}
    >
      <Grid container spacing={4}>
        <Grid item xs={3}></Grid>
        <Grid
          item
          xs={9}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          {isProjectEditPage ? (
            currentUser._id === project.techOwner ||
            currentUser._id === project.salesOwner ||
            currentUser.role === "ADMIN" ? (
              toEdit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item>
                      <SplitButton project={project} navigate={navigate} />
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<ModeEditIcon />}
                        fontSize="small"
                        style={{ marginRight: "28px" }}
                        variant="contained"
                        size="large"
                        onClick={() => {
                          setToEdit(false);
                          setdisplay("");
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    style={{ marginRight: "28px", display: "display" }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    size="large"
                    onClick={() => {
                      UpdateProject();
                    }}
                  >
                    Save
                  </Button>
                  <div>&nbsp;</div>
                  <Button
                    style={{
                      display: "display",
                      marginRight: "2px",
                      height: "48px",
                      width: "107.39px",
                    }}
                    variant="contained"
                    startIcon={<CancelIcon />}
                    onClick={() => {
                      setProjectLead({ ...tempProjectLead });
                      setToEdit(true);
                      setdisplay("none");
                      setCustomAttributes(project.attributes);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )
            ) : null
          ) : null}
        </Grid>
      </Grid>
      <CardContent>
        <FormControl>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-lead-select-label" required>
                    {`${leadDynamicTitle?.displayName.singularName} Summary`}
                  </InputLabel>
                  <Select
                    inputProps={{ readOnly: isProjectEditPage ? true : false }}
                    labelId="demo-lead-select-label"
                    id="demo-lead-select"
                    label={`${leadDynamicTitle?.displayName.singularName} Summary`}
                    name="leadId"
                    value={project.leadId}
                    onChange={handleChange}
                  >
                    {leads.length > 0
                      ? leads.map((lead, index) => {
                          return (
                            <MenuItem key={index} value={lead._id}>
                              {lead.summary} <br /> Contact Name:{" "}
                              {lead.contact_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                label={`${contactDynamicTitle?.displayName.singularName} Name`}
                variant="outlined"
                placeholder={`${contactDynamicTitle?.displayName.singularName} Name`}
                type="text"
                name="name"
                required
                value={
                  isProjectEditPage
                    ? project.contact.name
                    : contact.contact_name
                }
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                inputProps={{
                  readOnly: isProjectEditPage ? (toEdit ? true : false) : false,
                }}
                label="Title"
                variant="outlined"
                placeholder="Title"
                type="text"
                name="title"
                required
                value={isProjectEditPage ? projectLead.title : project.title}
                fullWidth
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Autocomplete
                  readOnly={toEdit ? true : false}
                  multiple
                  clearOnBlur
                  freeSolo
                  key={randomKey}
                  id="techStackAutoComplete"
                  noOptionsText={
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            size: "large",
                          }}
                          onClick={handleClickOpen}
                        >
                          ADD NEW
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  // options={techStacks.length === 0 ? ["ADD NEW"] : techStacks}
                  options={techStacks}
                  getOptionLabel={(option) => option?.name || option}
                  renderOption={(props, option) => {
                    console.log("Option", option);
                    return (
                      <Grid container spacing={0}>
                        {option.name === "+ Add" ? (
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                size: "large",
                              }}
                              onClick={handleClickOpen}
                            >
                              ADD NEW
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <li {...props} style={{ borderColor: "white" }}>
                              {option.name}
                            </li>
                          </Grid>
                        )}
                      </Grid>
                    );
                  }}
                  value={
                    isProjectEditPage
                      ? projectLead.techStack > 0
                        ? handleEmptySkillsChip(projectLead.techStack)
                        : projectLead.techStack
                      : project.techStack > 0
                      ? handleEmptySkillsChip(project.techStack)
                      : project.techStack
                  }
                  filterSelectedOptions={true}
                  onChange={(event, newValue, reason) => {
                    event.stopPropagation();
                    console.log("newValue", newValue, "reason", reason);

                    const hasDuplicates = (newSkills) => {
                      const skillCounts = newSkills.reduce((acc, skill) => {
                        const skillName = skill.name.toLowerCase();
                        acc[skillName] = (acc[skillName] || 0) + 1;
                        return acc;
                      }, {});
                      console.log(skillCounts, "skillcount");
                      return Object.values(skillCounts).some(
                        (count) => count > 1
                      );
                    };
                    function setChips(newValueArray) {
                      if (isProjectEditPage) {
                        if (hasDuplicates(newValueArray)) {
                          toast.warn(
                            "You cannot add the same skills, please choose a new one"
                          );
                          return;
                        }
                        setProjectLead((prevState) => ({
                          ...prevState,
                          techStack: [...newValueArray],
                        }));
                      } else {
                        setProject((prevState) => ({
                          ...prevState,
                          techStack: [...newValueArray],
                        }));
                      }
                    }
                    switch (reason) {
                      case "createOption":
                        console.log("");
                        newValue.forEach((e) => {
                          if (typeof e === "string" && e.trim() !== "") {
                            let newData = { name: e };
                            newValue.pop(e);
                            setChips([...newValue, newData]);
                          }
                        });
                        break;
                      case "removeOption":
                      case "selectOption":
                      case "clear":
                        setChips(newValue);
                        break;
                      default:
                        break;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      id="textFieldValue"
                      {...params}
                      label="Techstacks"
                      placeholder="Click to Add"
                    />
                  )}
                />
                <span
                  style={{
                    fontSize: "10px",
                    marginLeft: "8px",
                    color: "#808080",
                  }}
                >
                  *To Add An Unregistered Tech Stack, Write Its Name & Hit Enter
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Specification"
                inputProps={{
                  readOnly: isProjectEditPage ? (toEdit ? true : false) : false,
                }}
                id="demo-userId-select"
                variant="outlined"
                placeholder="Specification"
                type="text"
                name="specification"
                required
                value={
                  isProjectEditPage
                    ? projectLead.specification
                    : project.specification
                }
                fullWidth
                onChange={handleChange}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                inputProps={{
                  readOnly: isProjectEditPage ? (toEdit ? true : false) : false,
                }}
                label="Details"
                variant="outlined"
                required
                placeholder="Details"
                type="text"
                name="details"
                multiline
                rows={2}
                value={
                  isProjectEditPage ? projectLead.details : project.details
                }
                fullWidth
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  fullWidth
                  readOnly
                  label="Start Date"
                  inputFormat="MMM/dd/yyyy"
                  value={project.timeline.committedOn}
                  name="startline"
                  onChange={handleStartlineDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} required>
                <DesktopDatePicker
                  required
                  fullWidth
                  readOnly={isProjectEditPage ? (toEdit ? true : false) : false}
                  label="Due Date"
                  inputFormat="MMM/dd/yyyy"
                  minDate={new Date(project.timeline.committedOn).setDate(
                    new Date(project.timeline.committedOn).getDate() + 1
                  )}
                  value={project.timeline.deadline}
                  name="deadline"
                  onChange={handleDeadlineDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              {customAttributes.length ? (
                <Grid container spacing={4} mt="2px">
                  {customAttributes.map((attribute, index) => (
                    <Grid item xs={6} key={index}>
                      <EntityAttributeFields
                        componentIndex={index}
                        componentDeatils={attribute}
                        allAttributes={customAttributes}
                        setterFunction={setCustomAttributes}
                        entityName={"Project"}
                        toEdit={toEdit}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </Grid>

            {resource.map((e, index) => {
              return isProjectEditPage ? null : (
                <Grid item xs={12} key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={3.75}>
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel id="demo-userId-select-label">
                            Resource Name
                          </InputLabel>
                          <Select
                            inputProps={{
                              readOnly: isProjectEditPage
                                ? toEdit
                                  ? true
                                  : false
                                : false,
                            }}
                            labelId="demo-userId-select-label"
                            id="demo-userId-select"
                            label="Lead Summary"
                            name="userId"
                            value={
                              isProjectEditPage
                                ? resource[index].userId
                                : resource[index].userId
                            }
                            onChange={(e) => {
                              setResource((prevState) => {
                                const temp = [...prevState];
                                temp[index].userId = e.target.value;
                                return temp;
                              });
                            }}
                          >
                            {users.length > 0
                              ? users.map((user, index) => {
                                  return (
                                    <MenuItem key={index} value={user._id}>
                                      {user.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={3.75}>
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel id="demo-assignedRole-select-label">
                            Resource Role
                          </InputLabel>
                          <Select
                            inputProps={{
                              readOnly: isProjectEditPage
                                ? toEdit
                                  ? true
                                  : false
                                : false,
                            }}
                            labelId="demo-assignedRole-select-label"
                            id="demo-assignedRole-select"
                            label="Resource Role"
                            name="assignedRole"
                            value={resource[index].assignedRole}
                            onChange={(e) => {
                              setResource((prevState) => {
                                const temp = [...prevState];
                                temp[index].assignedRole = e.target.value;
                                return temp;
                              });
                            }}
                          >
                            {roles.length > 0
                              ? roles.map((user, index) => {
                                  return (
                                    <MenuItem key={index} value={user.value}>
                                      {user.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={3.75}>
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel id="demo-contribution-select-label">
                            Resource Time
                          </InputLabel>
                          <Select
                            inputProps={{
                              readOnly: isProjectEditPage
                                ? toEdit
                                  ? true
                                  : false
                                : false,
                            }}
                            labelId="demo-contribution-select-label"
                            id="demo-contribution-select"
                            label="Resource Time"
                            name="contribution"
                            value={resource[index].contribution}
                            onChange={(e) => {
                              setResource((prevState) => {
                                const temp = [...prevState];
                                temp[index].contribution = e.target.value;
                                return temp;
                              });
                            }}
                          >
                            {timing.length > 0
                              ? timing.map((time, index) => {
                                  return (
                                    <MenuItem key={index} value={time.value}>
                                      {time.name}
                                    </MenuItem>
                                  );
                                })
                              : null}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={0.75}>
                      <CancelIcon
                        onClick={() => {
                          console.log("Inside on click delete");
                          setResource((prevState) => {
                            const temp = [...prevState];
                            console.log("Index", index);
                            temp.splice(index, 1);
                            return temp;
                          });
                        }}
                      >
                        X
                      </CancelIcon>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            {isProjectEditPage ? null : (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: "flex", flexDirection: "row-reverse" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="buttonClicked"
                  onClick={handleCounter}
                >
                  Add Resource
                </Button>
              </Grid>
            )}
          </Grid>
        </FormControl>
      </CardContent>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new techstack write its name in the below textbox
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Techstack"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleEmptySkills(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            CANCEL
          </Button>
          <Button onClick={addNewTechstackAndShowChip}> ADD</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProjectInfo;
