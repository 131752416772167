import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Card,
  Grid,
  Stack,
  TextField,
  ListItemText,
  Checkbox,
  InputLabel,
  Tooltip,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";

import constants from "../../constants";
import { getContactSearch } from "../../services/contacts.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProjectSearch } from "../../services/projects.service";
import Iconify from "../UI/Iconify";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [{ Title: "title" }, { Country: "country" }];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SearchProject({
  setCount,
  projects,
  tempProjects,
  setProjects,
  column,
  setColumn,
  typeText,
  projectStatus,
  setTypeText,
  setPage,
  page,
  setPageCount,
  setIsLoading,
  setSelectStatus,
  selectStatus,
  fetchProjects,
}) {
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isEmptyTextField, setIsEmptyTextField] = React.useState(false);
  // const [selectStatus, setSelectStatus] = React.useState([]);

  const theme = useTheme();
  //   const [column, setColumn] = React.useState("");

  //   let result = constants.CONTACTDROPDOWN.map((e) => e);
  console.log("In search value", projects, typeText);
  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
  };

  const searchChange = (e) => {
    setProjects([]);
    setTypeText(e.target.value);
    // setCount(1);
    setPageCount(1);
    setPage(0);

    console.log("setTypetext", e.target.value);
  };
  React.useEffect(() => {
    if (typeText) {
      setIsLoading(true);
      setIsEmpty(false);
      setIsEmptyTextField(true);
    } else {
      // setColumn("");
      // setIsEmpty(true);
      setIsEmptyTextField(false);
    }
    const lowerColumnName = column.toLowerCase();
    const timerId = setTimeout(() => {
      getProjectSearch(lowerColumnName, typeText).then((response) => {
        //   console.log(response.data.data.counts);
        // setContacts([response.data.data.allContacts]);
        const result = response.data.data ? response.data.data.allProjects : [];
        console.log("response", response.data);
        setProjects((prevState) => [...prevState, ...result]);
        setCount(result.length);
        setIsLoading(false);
      });
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [typeText]);

  // React.useEffect(() => {
  //   if (selectStatus.length > 0) {
  //     const filteredProjects = projects.filter((proj) =>
  //       selectStatus.includes(proj?.status?.name)
  //     );

  //     setProjects((prevState) => {
  //       return filteredProjects;
  //     });
  //   } else {
  //     setProjects(projects);
  //   }
  // }, [selectStatus, projects]);
  const handleClearIconClick = () => {
    console.log("clearicon click");
    setProjects((prevProjects) => {
      return [];
    });
    setIsLoading(true);
    setSelectStatus([]);
    fetchProjects(true);
  };

  const handelFilter = () => {
    // debugger;
    if (selectStatus.length > 0) {
      console.log("filter true");
      setIsLoading(true);

      const filteredProjects = tempProjects.filter((proj) => {
        // console.log(proj?.status?.name, "statusv");
        return selectStatus.includes(proj?.status?.name);
      });

      setTimeout(() => {
        setProjects(filteredProjects);
        setCount(filteredProjects.length);
        setIsLoading(false);
      }, 0);
      console.log(filteredProjects, "statusv");
    } else {
      console.log("filter false");
      setProjects((prevProjects) => {
        return [];
      });
      setIsLoading(true);
      fetchProjects(true);
    }
  };

  const handleStatusChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectStatus(typeof value === "string" ? value.split(",") : value);
  };
  // console.log(tempProjects, "statusvalue");

  return (
    <Stack direction="row" spacing={1}>
      <Select
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        displayEmpty
        value={column}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isEmpty) {
            return <>Select Column</>;
          }

          return selected;
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <>Select Column</>
        </MenuItem>
        <MenuItem value="Title">Title</MenuItem>
        {/* <MenuItem value="contact_name">Contact Name</MenuItem> */}
      </Select>

      <TextField
        size="small"
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        inputProps={column ? "" : { readOnly: true }}
        label="Search"
        variant="outlined"
        value={typeText}
        onChange={searchChange}
        setPage={setPage}
        page={page}
        InputLabelProps={{ shrink: isEmptyTextField }}
        onClick={() => {
          if (!column) {
            notify();
          }
        }}
      />
      <FormControl fullWidth size="small">
        <InputLabel>Status</InputLabel>
        <Select
          sx={{ minWidth: "200px", maxHeight: "40px" }}
          // inputProps={{
          //   endAdornment: (
          //     <>
          //       <div
          //         style={{
          //           marginRight: "8px",
          //           display: "inline-flex",
          //         }}
          //       >
          //         <div className="MuiSelect-icon" />
          //       </div>

          //       {/* Your custom clear icon */}
          //       <ClearIcon
          //         onClick={handleClearIconClick}
          //         style={{ cursor: "pointer", marginRight: "8px" }}
          //       />
          //     </>
          //   ),
          // }}
          IconComponent={() =>
            selectStatus.length > 0 ? (
              <Tooltip title="clear filter">
                <ClearIcon
                  onClick={handleClearIconClick}
                  style={{ cursor: "pointer", marginRight: "18px" }}
                />
              </Tooltip>
            ) : null
          }
          multiple
          label="Status"
          name="status"
          // value={leadFilter.status}
          value={selectStatus}
          renderValue={(value) => value.join(", ")}
          onChange={handleStatusChange}
        >
          {/* <MenuItem value="ALL">
            <Checkbox
              checked={true}
              // checked={allChecked.status}
            />
            <ListItemText primary="ALL" />
          </MenuItem> */}
          {projectStatus.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                <Checkbox checked={selectStatus.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "10px",
          marginTop: "-6px",
        }}
      >
        <IconButton
          color="primary"
          onClick={() => handelFilter()}
          disabled={!selectStatus.length > 0}
          aria-label="add to filter"
          fontSize="large"
        >
          <ManageSearchIcon
            // sx={{ fontSize: 30 }}
            fontSize="large"
          />
        </IconButton>

        {/* <Button
          variant="contained"
          size="medium"
          disabled={!selectStatus.length > 0}
          onClick={() => handelFilter()}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          startIcon={<ManageSearchIcon />}
        /> */}
      </div>
    </Stack>
  );
}
