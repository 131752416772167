import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  Stack,
  TextField,
} from "@mui/material";
import { getContactSearch } from "../../services/contacts.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoCompleteSearch from "../Autocomplete/AutoCompleteSearch";
import { useDynamicCompanyTitle } from "../../pages/Companies/Companies";
import { useSearchCollectionName } from "../../utils/filterObject";

export default function SearchContact({
  setCount,
  contacts,
  setContacts,
  column,
  setColumn,
  typeText,
  setTypeText,
  setPage,

  setPageCount,
  setIsLoading,
  initialIntervals,
  intervals,
  setIntervals,
  handleIntervalChange,
  showChip,
  formatCustomDate,
  startDate,
  endDate,
  afterRemoveChip,
  dateFilteredContacts,
  fetchContact,
}) {
  const companyDynamicTitle = useDynamicCompanyTitle();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isEmptyTextField, setIsEmptyTextField] = React.useState(false);
  const theme = useTheme();
  const contactPage = "contactPage";
  //   const [column, setColumn] = React.useState("");

  //   let result = constants.CONTACTDROPDOWN.map((e) => e);
  console.log("In search value", contacts, typeText);
  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
    setTypeText("");
  };

  const searchChange = (e) => {
    setContacts([]);
    setTypeText(e.target.value);
    // setCount(1);
    setPageCount(1);
    setPage(0);
    // searchExecution = null;
    console.log("setTypetext", e.target.value);
  };
  const clearSelectColumn = async () => {
    setIntervals(() => initialIntervals[3]);
    setColumn("");
    setTypeText("");
    fetchContact();
    setIsEmpty(true);
    afterRemoveChip();
  };
  const getCollectionName = useSearchCollectionName(column);
  React.useEffect(() => {
    if (column) {
      setIsLoading(true);
      setIsEmpty(false);
      setIsEmptyTextField(true);
    } else {
      setColumn("");
      setIsEmpty(true);
      setIsEmptyTextField(false);
    }
    // const lowerColumnName = column.toLowerCase();

    const lowerColumnName = getCollectionName.toLowerCase();

    // setContacts([]);
    const timerId = setTimeout(() => {
      getContactSearch(lowerColumnName, typeText).then((response) => {
        //   console.log(response.data.data.counts);
        // setContacts([response.data.data.allContacts]);
        const result = response.data.data ? response.data.data.allContacts : [];
        console.log("response", response.data);
        setContacts([...result]);
        setCount(result.length);
        setIsLoading(false);
      });
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [typeText]);

  return (
    <Stack direction="row" spacing={1.3}>
      <Select
        sx={{ minWidth: "200px", maxHeight: "40px" }}
        displayEmpty
        value={column}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (isEmpty) {
            return <>Select Column</>;
          }

          return selected;
        }}
      >
        <MenuItem disabled value="">
          <>Select Column</>
        </MenuItem>
        <MenuItem value="Name">Name</MenuItem>
        <MenuItem value={companyDynamicTitle?.displayName.singularName}>
          {companyDynamicTitle?.displayName.singularName}
        </MenuItem>
        <MenuItem value="Country">Country</MenuItem>
        <MenuItem value="Date">Date</MenuItem>
      </Select>

      {column === companyDynamicTitle?.displayName.singularName ? (
        <>
          <AutoCompleteSearch
            sx={{ minWidth: "200px", maxHeight: "40px" }}
            contactPage={contactPage}
            // lead={leads}
            // setLead={setLeads}
            searchChange={searchChange}
            column={column}
            typeText={typeText}
            setTypeText={setTypeText}
            companyDynamicTitle={companyDynamicTitle?.displayName.singularName}
          />
          {column !== "" && (
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={clearSelectColumn}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Clear
            </Button>
          )}
        </>
      ) : column === "Date" ? (
        <>
          {showChip ? (
            <div
              style={{
                minWidth: "200px",
                maxHeight: "40px",
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "center",
              }}
            >
              <Chip
                sx={{ minWidth: "200px", maxHeight: "40px" }}
                label={`${formatCustomDate(
                  new Date(startDate).toDateString()
                )} - ${formatCustomDate(new Date(endDate).toDateString())}`}
                variant="outlined"
                onDelete={afterRemoveChip}
              />
            </div>
          ) : (
            <>
              <Autocomplete
                sx={{ minWidth: "200px", maxHeight: "40px" }}
                id="size-small-outlined"
                size="small"
                options={initialIntervals}
                getOptionLabel={(option) => option.label}
                // defaultValue={intervals[3]}
                value={intervals}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Interval"
                    placeholder="Select Interval"
                    name="interval"
                  />
                )}
                onChange={handleIntervalChange}
              />
            </>
          )}
          <ButtonGroup variant="contained" aria-label="Basic button group">
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={clearSelectColumn}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={dateFilteredContacts}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Fetch
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <TextField
            size="small"
            sx={{ minWidth: "200px", maxHeight: "40px" }}
            inputProps={column ? "" : { readOnly: true }}
            label="Search"
            variant="outlined"
            value={typeText}
            onChange={searchChange}
            onClick={() => {
              if (!column) {
                notify();
              }
            }}
          />
          {column !== "" && (
            <Button
              variant="contained"
              startIcon={<ClearIcon />}
              onClick={clearSelectColumn}
              sx={{ minWidth: "100px", maxHeight: "40px" }}
            >
              Clear
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}
