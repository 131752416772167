import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, Grid, Stack, TextField } from "@mui/material";
import constants from "../../constants";
import { getContactSearch } from "../../services/contacts.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserSearch } from "../../services/users.service";
import { getLeadsSearch } from "../../services/leads.service";
import AutoCompleteSearch from "../Autocomplete/AutoCompleteSearch";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [{ Name: "name" }, { Country: "country" }];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SearchLead({
  setCount,
  leads,
  setLeads,
  column,
  setColumn,
  typeText,
  setTypeText,
  setPageCount,
  setPage,
  setIsLoading,
}) {
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isEmptyTextField, setIsEmptyTextField] = React.useState(false);

  console.log("In search value", leads, typeText, column);
  const notify = () => toast("Please Select a column!");
  const handleChange = (event) => {
    setIsEmpty(false);
    const {
      target: { value },
    } = event;
    setColumn(value);
    setTypeText("");
  };

  const searchChange = (e) => {
    setLeads([]);
    setTypeText(e.target.value);
    // setCount(1);
    setPageCount(1);
    setPage(0);

    console.log("setTypetext", e.target.value);
  };
  React.useEffect(() => {
    if (column) {
      setIsLoading(true);
      setIsEmpty(false);
      setIsEmptyTextField(true);
    } else {
      setColumn("");
      setIsEmpty(true);
      setIsEmptyTextField(false);
    }
    const lowerColumnName = column.toLowerCase();

    const timerId = setTimeout(() => {
      getLeadsSearch(lowerColumnName, typeText).then((response) => {
        if (response.data.data) {
          const result = response.data.data.allLeads
            ? response.data.data.allLeads
            : [];

          setLeads(result);
          setCount(result.length);
        } else {
          const result = response.data ? response.data : [];

          setLeads((prevState) => [...prevState, ...result]);
          setCount(result.length);
        }
        setIsLoading(false);
      });
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [typeText]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Select
          fullWidth
          size="small"
          displayEmpty
          value={column}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (isEmpty) {
              return <>Select Column</>;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <>Select Column</>
          </MenuItem>
          <MenuItem value="Name">Name</MenuItem>
          <MenuItem value="Country">Country</MenuItem>
          <MenuItem value="Skill">Skill</MenuItem>
          <MenuItem value="Summary">Summary</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6}>
        {column === "Skill" ? (
          <AutoCompleteSearch
            lead={leads}
            setLead={setLeads}
            column={column}
            typeText={typeText}
            setTypeText={setTypeText}
            searchChange={searchChange}
          />
        ) : (
          <TextField
            size="small"
            fullWidth
            inputProps={column ? "" : { readOnly: true }}
            label="Search"
            variant="outlined"
            value={typeText}
            onChange={searchChange}
            InputLabelProps={{ shrink: isEmptyTextField }}
            onClick={() => {
              if (!column) {
                notify();
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
