import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTechStacks } from "../../services/projects.service";
import { getCompanyNames } from "../../services/contacts.service";
const TAG = "AutoCompleteSearch.js";

export default function AutoCompleteSearch({
  typeText,
  setTypeText,
  lead,
  setLead,
  contactPage,
  searchChange,
  companyDynamicTitle,
}) {
  const [techStacks, setTechStacks] = useState([]);
  const [companyName, setCompanyName] = useState([]);

  const fetchTechStacks = async () => {
    try {
      const response = await getTechStacks();
      if (response.data.status) {
        console.log(
          "fetchTechStacks",
          response.data.status,
          response.data.data
        );
        let techStacksResponse = [...response.data.data];
        techStacksResponse.splice(-1);
        setTechStacks((prevState) => [...prevState, ...techStacksResponse]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompanyName = async () => {
    try {
      const response = await getCompanyNames();
      if (response.data.status) {
        console.log(
          "fetchCompanyName",
          response.data.status,
          response.data.data
        );
        let companyNameResponse = [...response.data.data];
        // companyNameResponse.splice(-1);
        // setCompanyName((prevState) => [...prevState, ...companyNameResponse]);
        setCompanyName(companyNameResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAutocompleteDropDown = () => {
    if (contactPage === "contactPage") {
      // console.log("fetchCompanyName", techStacks);
      fetchCompanyName();
    } else {
      // console.log("fetchTechStacks", techStacks);
      fetchTechStacks();
    }
  };

  useEffect(() => {
    getAutocompleteDropDown();
  }, []);
  console.log("fetchCompanyName", companyName);
  console.log("fetchTechStacks", techStacks);
  return (
    // <Grid item xs={12}>
    // <Box>
    <Autocomplete
      freeSolo
      size="small"
      fullWidth
      multiple
      id="tags-standard"
      value={typeText === "" ? [] : typeText.split(",")}
      onChange={(event, value, reason) => {
        searchChange(event);
        setTypeText(value.filter((element) => element.trim() !== "").join(","));
      }}
      filterSelectedOptions
      options={
        contactPage
          ? companyName.map((element) => element.companyName)
          : techStacks.map((element) => element.name)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={contactPage ? companyDynamicTitle : "Skills"}
          placeholder="Click to Add"
        />
      )}
    />
    // </Box>
    // </Grid>
  );
}
