import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Grid, Typography, Box } from "@mui/material";

// const data = [
//   {
//     country: "AD",
//     "hot dog": 103,
//     "hot dogColor": "hsl(299, 70%, 50%)",
//     burger: 153,
//     burgerColor: "hsl(31, 70%, 50%)",
//     sandwich: 98,
//     sandwichColor: "hsl(228, 70%, 50%)",
//     kebab: 198,
//     kebabColor: "hsl(124, 70%, 50%)",
//     fries: 13,
//     friesColor: "hsl(17, 70%, 50%)",
//     donut: 123,
//     donutColor: "hsl(18, 70%, 50%)",
//   },
//   {
//     country: "AE",
//     "hot dog": 126,
//     "hot dogColor": "hsl(313, 70%, 50%)",
//     burger: 167,
//     burgerColor: "hsl(354, 70%, 50%)",
//     sandwich: 109,
//     sandwichColor: "hsl(179, 70%, 50%)",
//     kebab: 190,
//     kebabColor: "hsl(51, 70%, 50%)",
//     fries: 77,
//     friesColor: "hsl(313, 70%, 50%)",
//     donut: 19,
//     donutColor: "hsl(73, 70%, 50%)",
//   },
//   {
//     country: "AF",
//     "hot dog": 67,
//     "hot dogColor": "hsl(16, 70%, 50%)",
//     burger: 25,
//     burgerColor: "hsl(265, 70%, 50%)",
//     sandwich: 78,
//     sandwichColor: "hsl(335, 70%, 50%)",
//     kebab: 128,
//     kebabColor: "hsl(269, 70%, 50%)",
//     fries: 82,
//     friesColor: "hsl(20, 70%, 50%)",
//     donut: 93,
//     donutColor: "hsl(279, 70%, 50%)",
//   },
//   {
//     country: "AG",
//     "hot dog": 63,
//     "hot dogColor": "hsl(215, 70%, 50%)",
//     burger: 197,
//     burgerColor: "hsl(191, 70%, 50%)",
//     sandwich: 159,
//     sandwichColor: "hsl(241, 70%, 50%)",
//     kebab: 44,
//     kebabColor: "hsl(2, 70%, 50%)",
//     fries: 144,
//     friesColor: "hsl(147, 70%, 50%)",
//     donut: 15,
//     donutColor: "hsl(108, 70%, 50%)",
//   },
//   {
//     country: "AI",
//     "hot dog": 28,
//     "hot dogColor": "hsl(270, 70%, 50%)",
//     burger: 37,
//     burgerColor: "hsl(172, 70%, 50%)",
//     sandwich: 18,
//     sandwichColor: "hsl(3, 70%, 50%)",
//     kebab: 199,
//     kebabColor: "hsl(210, 70%, 50%)",
//     fries: 75,
//     friesColor: "hsl(294, 70%, 50%)",
//     donut: 35,
//     donutColor: "hsl(317, 70%, 50%)",
//   },
//   {
//     country: "AL",
//     "hot dog": 81,
//     "hot dogColor": "hsl(130, 70%, 50%)",
//     burger: 145,
//     burgerColor: "hsl(332, 70%, 50%)",
//     sandwich: 133,
//     sandwichColor: "hsl(150, 70%, 50%)",
//     kebab: 105,
//     kebabColor: "hsl(137, 70%, 50%)",
//     fries: 44,
//     friesColor: "hsl(286, 70%, 50%)",
//     donut: 171,
//     donutColor: "hsl(130, 70%, 50%)",
//   },
//   {
//     country: "AM",
//     "hot dog": 119,
//     "hot dogColor": "hsl(144, 70%, 50%)",
//     burger: 57,
//     burgerColor: "hsl(353, 70%, 50%)",
//     sandwich: 62,
//     sandwichColor: "hsl(186, 70%, 50%)",
//     kebab: 96,
//     kebabColor: "hsl(264, 70%, 50%)",
//     fries: 110,
//     friesColor: "hsl(291, 70%, 50%)",
//     donut: 14,
//     donutColor: "hsl(163, 70%, 50%)",
//   },
// ];
const data = [
  {
    country: "AD",

    burger: 153,
    burgerColor: "hsl(31, 70%, 50%)",
  },
  {
    country: "AE",

    sandwich: 109,
    sandwichColor: "hsl(179, 70%, 50%)",
  },
  {
    country: "AF",

    donut: 93,
    donutColor: "hsl(279, 70%, 50%)",
  },
  {
    country: "AG",

    fries: 144,
    friesColor: "hsl(147, 70%, 50%)",
  },
  {
    country: "AI",

    sandwich: 18,
    sandwichColor: "hsl(3, 70%, 50%)",
  },
  {
    country: "AL",

    kebab: 105,
    kebabColor: "hsl(137, 70%, 50%)",
  },
  {
    country: "AM",

    fries: 110,
    friesColor: "hsl(291, 70%, 50%)",
  },
];

const Nivobar = ({
  reportFigures,
  salesOwnerFigure,
  reportData,
  isCompared,
  status,
  countries,
  selectOwnerList,
  OwnersNameList,
  salesOwnerName,
  primaryComparetor,
}) => {
  let maxValue;
  const [maxValues, setMaxValues] = useState({});
  let Total = Object.values(reportFigures).reduce((acc, curr) => acc + curr, 0);
  console.log(selectOwnerList, OwnersNameList, "modi2");

  const renderStatus = (listArray) => {
    if (listArray.length > 4) {
      if (listArray.length === 5) {
        const firstThree = listArray.slice(0, 3);

        const additionalCount = listArray.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = listArray.slice(0, 4);

      const additionalCount = listArray.length - 4;

      return `${firstThree.join(",")} and ${additionalCount} others`;
    } else {
      // return listArray.join(", ");

      const listCopy = [...listArray];
      const lastElement = listCopy.pop();
      console.log(`${listCopy.join(", ")} and ${lastElement}`, "myelseee");
      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };

  const renderSalesOwners = (userIds, userInfo) => {
    const filteredUsers = userInfo.filter((user) => userIds.includes(user._id));

    const userNames = filteredUsers.map((user) => user.name);

    if (userNames.length > 4) {
      if (userNames.length === 5) {
        const firstThree = userNames.slice(0, 3);

        const additionalCount = userNames.length - 3;

        return `${firstThree.join(",")} and ${additionalCount} others`;
      }
      const firstThree = userNames.slice(0, 4);
      const additionalCount = userNames.length - 4;
      return `${firstThree.join(", ")} and ${additionalCount} others`;
    } else {
      // return userNames.join(", ");
      const listCopy = [...userNames];
      const lastElement = listCopy.pop();

      return `${listCopy.join(", ")} and ${lastElement}`;
    }
  };

  useEffect(() => {
    if (salesOwnerFigure && salesOwnerFigure.length > 0) {
      const calculateMaxValues = () => {
        return salesOwnerFigure.reduce((maxValues, { reportFigures }) => {
          Object.entries(reportFigures).forEach(([key, value]) => {
            maxValues[key] = Math.max(maxValues[key] || 0, value);
          });
          return maxValues;
        }, {});
      };

      setMaxValues(() => calculateMaxValues());
    }
  }, [salesOwnerFigure]);

  console.log(maxValue, isCompared, "maxValues");

  //  maxValue = getMaxValue();

  const modifiedData = Object.keys(reportFigures).map((key) => ({
    leads: key.charAt(0).toUpperCase() + key.slice(1),
    [key.charAt(0).toUpperCase() + key.slice(1)]: reportFigures[key],

    [key + "Color"]: `hsl(${Math.random() * 360}, 70%, 50%)`, // Generating random colors
  }));
  console.log(salesOwnerFigure, " mofiga");
  return (
    <>
      {isCompared && (
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column" }}
          spacing={0}
        >
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Status"
                  : primaryComparetor === "Country"
                  ? "Sales Owner"
                  : "Country"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(status)
                : primaryComparetor === "Country"
                ? renderSalesOwners(selectOwnerList, OwnersNameList)
                : renderStatus(countries)}
            </Typography>
          </Box>
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <Typography variant="caption" display="block" gutterBottom>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {" "}
                {primaryComparetor === "Sales Owner"
                  ? "Country"
                  : primaryComparetor === "Country"
                  ? "Status"
                  : "Sales Owner"}
              </span>{" "}
              :{" "}
              {primaryComparetor === "Sales Owner"
                ? renderStatus(countries)
                : primaryComparetor === "Country"
                ? renderStatus(status)
                : renderSalesOwners(selectOwnerList, OwnersNameList)}
            </Typography>
          </Box>
        </Grid>
      )}
      <div style={{ width: "100%", height: "54vh", margin: "auto" }}>
        <div style={{ width: "100%", height: "100%" }}>
          {maxValues &&
          salesOwnerFigure &&
          salesOwnerFigure.length &&
          isCompared ? (
            <ResponsiveBar
              data={modifiedData}
              // keys={["opportunity", "close", "drop", "lead", "prospect"]}
              keys={Object.keys(reportFigures)
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .reverse()}
              indexBy="leads"
              margin={{
                top: 50,
                right: !isCompared ? 130 : 105,
                bottom: 50,
                left: 60,
              }}
              padding={0.3}
              // valueScale={{ type: "linear" }}
              valueScale={{
                type: "linear",
                max: Math.max(...Object.values(maxValues)),
              }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "opportunity",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "lead",
                  },
                  id: "lines",
                },
              ]}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Lead",
                legendPosition: "middle",
                legendOffset: 40,
                truncateTickAt: 0,
              }}
              theme={{
                background: "#ffffff",
                text: {
                  fontSize: 11,
                  fill: "#cf0c0c",
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 15,
                      fill: "#1878f7",
                      outlineWidth: 4,
                      outlineColor: "transparent",
                    },
                  },
                  ticks: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#dddddd",
                    strokeWidth: 1,
                  },
                },
                legends: {
                  title: {
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                  text: {
                    fontSize: 11,
                    fill: "#333333",
                    outlineWidth: 0,
                    outlineColor: "transparent",
                  },
                },
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -45,
                truncateTickAt: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
            />
          ) : (
            <ResponsiveBar
              data={modifiedData}
              keys={Object.keys(reportFigures)
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .reverse()}
              indexBy="leads"
              margin={{
                top: 50,
                right: !isCompared ? 130 : 90,
                bottom: 50,
                left: 60,
              }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              // valueScale={{
              //   type: "linear",
              //   max: Math.max(...Object.values(maxValues)),
              // }}
              indexScale={{ type: "band", round: true }}
              colors={{ scheme: "nivo" }}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              fill={[
                {
                  match: {
                    id: "opportunity",
                  },
                  id: "dots",
                },
                {
                  match: {
                    id: "lead",
                  },
                  id: "lines",
                },
              ]}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              theme={{
                background: "#ffffff",
                text: {
                  fontSize: 11,
                  fill: "#cf0c0c",
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                  },
                  legend: {
                    text: {
                      fontSize: 15,
                      // fill: "#e70d0d",
                      fill: "#1878f7",
                      outlineWidth: 4,
                      outlineColor: "transparent",
                    },
                  },
                  ticks: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                },
                grid: {
                  line: {
                    stroke: "#dddddd",
                    strokeWidth: 1,
                  },
                },
                legends: {
                  title: {
                    text: {
                      fontSize: 11,
                      fill: "#333333",
                      outlineWidth: 0,
                      outlineColor: "transparent",
                    },
                  },
                  text: {
                    fontSize: 11,
                    fill: "#333333",
                    outlineWidth: 0,
                    outlineColor: "transparent",
                  },
                },
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Lead",
                legendPosition: "middle",
                legendOffset: 36,
                truncateTickAt: 0,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -45,
                truncateTickAt: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
            />
          )}
        </div>
        {salesOwnerName ? (
          <Grid
            container
            sx={{
              // backgroundColor: "red",
              marginTop: "-5px",
            }}
            spacing={1}
          >
            <Grid xs={10} item>
              {" "}
              <Typography textAlign="start" fontWeight="300" color="#FF5733">
                <b>Name</b>: <b>{salesOwnerName}</b>
              </Typography>
            </Grid>
            <Grid xs={2} item>
              {" "}
              <Typography textAlign="end" fontWeight="300" color="#FF5733">
                <b>Total</b>: <b>{Total || 0}</b>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography textAlign="center" fontWeight="300" color="#FF5733">
            <b>Total</b>: <b>{Total || 0}</b>
          </Typography>
        )}
      </div>
    </>
  );
};

export default Nivobar;
