import React from "react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Page from "../../components/UI/Page";
import {
  Card,
  CardContent,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch,
  FormHelperText,
} from "@mui/material";
import ReportAll from "../../components/Reports/ReportAll";
import ReportDrop from "../../components/Reports/ReportDrop";
import { getReport } from "../../services/report.service";
import { getUsersByRoles } from "../../services/users.service";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context";
import { getIncludedCountries } from "../../services/contacts.service";
import Nivopie from "../Nivo/Nivopie";
import Nivobar from "../Nivo/Nivobar";
import Nivoline from "../Nivo/Nivoline";

const Reports = () => {
  const { currentUser } = useContext(AuthContext);
  const [popup, setPopup] = useState(false);
  const [interval, setInterval] = useState(
    new Date(new Date().setDate(new Date().getDate() - 60)).toDateString()
  );
  // new Date(new Date().setDate(new Date().getDate() - 30)).toDateString()
  const [colorSeven, setColorSeven] = useState("primary");
  const [colorTwo, setColorTwo] = useState("default");
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [reportInterval, setReportInterval] = useState(null);
  const [customDate, setCustomDate] = useState(false);
  const [showChip, setShowChip] = useState(false);

  const [showReportAll, setShowReportAll] = useState(true);
  const [refreshReportData, setRefreshReportData] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportFigures, setReportFigures] = useState({
    opportunity: 0,
    close: 0,
    drop: 0,
    lead: 0,
    prospect: 0,
  });
  const [primaryComparetor, setprimaryComparetor] = useState("Sales Owner");
  const [comparetorList, setComparetorList] = useState([
    "Sales Owner",
    "Status",
    "Country",
  ]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countries, setCountries] = useState([]);
  const [salesOwners, setSalesOwners] = useState([]);
  const [allChecked, setAllChecked] = useState({
    status: true,
    country: true,
    salesOwner: true,
  });

  let status = ["Lead", "Prospect", "Opportunity", "Drop", "Close"];

  const [leadFilter, setLeadFilter] = useState({
    status: [...status],
    country: [...countries],
    salesOwner: [],
  });

  const initialFilters = [
    {
      chartName: "Pie Chart",
      checked: true,
    },
    {
      chartName: "Bar Chart",
      checked: false,
    },
    {
      chartName: "Line Chart",
      checked: false,
    },
  ];
  const [chartFilter, setChartFilter] = useState([...initialFilters]);
  const [selectChartName, setSelectChartName] = useState("Pie Chart");
  const [isCompared, setIsCompared] = React.useState(false);
  const [salesOwnerFigure, setSalesOwnerFigure] = useState([]);

  {
    /**const getReportDataOnLoading = async () => {
    console.log("second hello");
    console.log(showChip);
    try {
      const filter = {};
      const onLoad = true;
      if (showChip) {
        filter.from = dateChange(startDate);
        filter.to = dateChange(endDate);
      } else {
        filter.from = dateChange(!showChip ? interval : startDate);
        filter.to = dateChange(endDate);
      }
      console.log(filter);
      const response = await getReport(filter, onLoad, pageCount);
      if (response.data.status) {
        setReportData(response.data.data.allLeads);
        setCount(response.data.data.allLeads.length);
        setReportFigures((prevState) => ({
          ...prevState,
          opportunity: response.data.data.opportunity,
          close: response.data.data.close,
          dropCount: response.data.data.drop,
          lead: response.data.data.lead,
          prospect: response.data.data.prospect,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };*/
  }

  const afterRemoveChip = () => {
    setCustomDate(false);
    setShowChip(false);
    setStartDate(new Date().setDate(new Date().getDate() - 1));
    setEndDate(new Date());
    setPage(0);
    setInterval("");
    setReportData([]);
    setReportFigures({
      opportunity: 0,
      close: 0,
      drop: 0,
      lead: 0,
      prospect: 0,
    });
  };

  const handleChange = (e) => {
    console.log(e.target.value, "my value");
    if (e.target.name === "interval") {
      if (e.target.value !== "Custom" && interval === "Custom") {
        toast("Close the chip to select another interval");
        return;
      }
      if (e.target.value === "Custom") {
        setPopup(true);
      }
      setInterval(e.target.value);
    }
  };
  console.log(interval, "my value");

  const handleComparetor = (e) => {
    setIsCompared(false);
    setprimaryComparetor(e.target.value);
  };

  const checkWhichDropdown = (name) => {
    if (name === "status") {
      return status;
    } else if (name === "country") {
      return countries;
    } else {
      const temp = [];
      salesOwners.map((item, index) => {
        if (item.active_status === true) {
          temp.push(item._id);
        }
      });
      return temp;
    }
  };

  const handleOnlyOneSalesOwner = (e) => {
    setLeadFilter((prevState) => {
      const temp = { ...prevState };
      temp.salesOwner = [e.target.value];
      return temp;
    });
  };

  const handleLeadFilter = (e) => {
    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length &&
      e.target.value[e.target.value.length - 1] === "ALL"
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });

      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = [];
        return temp;
      });

      return;
    }

    setLeadFilter((prevState) => {
      const temp = { ...prevState };
      temp[e.target.name] =
        typeof e.target.value === "string"
          ? e.target.value
              .filter((i) => {
                return i !== "ALL";
              })
              .split(",")
          : e.target.value.filter((i) => {
              return i !== "ALL";
            });
      return temp;
    });

    if (
      e.target.value.filter((i) => i !== "ALL").length ===
        checkWhichDropdown(e.target.name).length ||
      e.target.value[0] === "ALL" ||
      e.target.value.includes("ALL")
    ) {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = true;
        return temp;
      });
      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = [...checkWhichDropdown(e.target.name)];
        return temp;
      });
    } else {
      setAllChecked((prevState) => {
        const temp = { ...prevState };
        temp[e.target.name] = false;
        return temp;
      });
    }
  };
  // console.log(reportFigures, "mofiguresales");
  const handleSalesOwnerForRender = (value) => {
    const temp = [];
    const onlyActiveSalesOwners = [];
    salesOwners.forEach((user) => {
      if (user.active_status === true) {
        onlyActiveSalesOwners.push(user);
      }
    });
    if (value.length === 1) {
      salesOwners.forEach((e) => {
        if (e._id === value.toString()) {
          temp.push(e.name);
        }
      });
    } else if (value.length === onlyActiveSalesOwners.length) {
      return ["ALL"];
    } else {
      for (let index = 0; index < salesOwners.length; index++) {
        for (let index1 = 0; index1 < value.length; index1++) {
          if (salesOwners[index]._id === value[index1]) {
            temp.push(salesOwners[index].name);
          }
        }
      }
    }
    return temp.join(", ");
  };

  const handlePageChange = (e, newPage) => {
    console.log("newPage", newPage);
    if (
      rowsPerPage * (parseInt(newPage) + 1) > reportData.length &&
      reportData.length < pageCount
    ) {
      setPageCount((prevState) => prevState + 1);
    }
    setPage(newPage);
  };

  //format Date

  function dateChange(date) {
    const dateTimeInSeconds = new Date(date).getTime();
    const offSet = new Date().getTimezoneOffset();
    const offSetInSeconds = offSet * 60 * 1000;
    const newDateInSeconds = dateTimeInSeconds + offSetInSeconds;
    const newDate = new Date(newDateInSeconds);
    const newDateFormat = formatDate(newDate);
    return newDateFormat;
  }

  function formatDate(date) {
    date = new Date(date);

    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      "T" +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        // padTo2Digits(date.getSeconds()),
      ].join(":") +
      ":00" +
      ".000Z"
    );
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function getQuarterDates(interval) {
    const today = new Date();
    let year = today.getFullYear();
    const month = today.getMonth();
    let startMonth, endMonth;
    let startDate, endDate;
    console.log("checkquater", year);

    if (interval === "Current Quarter") {
      if (month >= 0 && month <= 2) {
        startMonth = 0;
        endMonth = month;
      } else if (month >= 3 && month <= 5) {
        startMonth = 3;
        endMonth = month;
      } else if (month >= 6 && month <= 8) {
        startMonth = 6;
        endMonth = month;
      } else {
        startMonth = 9;
        endMonth = month;
      }
    } else if (interval === "Last Quarter") {
      console.log(month, "checkquater");
      if (month >= 0 && month <= 2) {
        startMonth = 9;
        endMonth = 11;
        year -= 1;
      } else if (month >= 3 && month <= 5) {
        startMonth = 0;
        endMonth = 2;
      } else if (month >= 6 && month <= 8) {
        startMonth = 3;
        endMonth = 5;
      } else {
        startMonth = 6;
        endMonth = 8;
      }
    } else if (interval === "Current Month") {
      startDate = new Date(year, month, 1);
      endDate = today;
      return { startDate, endDate };
    }
    console.log(startMonth, year, endMonth, "checkquater");

    startDate = new Date(year, startMonth, 1);
    endDate =
      interval === "Current Quarter" ? today : new Date(year, endMonth + 1, 0);

    return { startDate, endDate };
  }

  //Api Calls

  const handleIntervals = (intervals) => {
    if (intervals !== "Custom") {
      if (!interval) {
        toast("Interval is mandatory");
        return;
      }
      console.log(intervals, "checkquater");
      if (intervals === "Last Quarter") {
        let { startDate, endDate } = getQuarterDates(intervals);

        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter);
        getReportData(filter, onLoad, pageCount);
        return;
      } else if (intervals === "Current Quarter") {
        let { startDate, endDate } = getQuarterDates(intervals);

        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter, { startDate, endDate });
        getReportData(filter, onLoad, pageCount);
        return;
      } else if (intervals === "Current Month") {
        let { startDate, endDate } = getQuarterDates(intervals);

        const filter = { from: dateChange(startDate), to: dateChange(endDate) };
        const onLoad = false;
        console.log("filter---->", filter, { startDate, endDate });
        getReportData(filter, onLoad, pageCount);
        return;
      }

      const filter = { from: dateChange(interval), to: "" };
      const onLoad = false;
      console.log("filter---->", filter);
      getReportData(filter, onLoad, pageCount);
    } else {
      console.log("check quater  else");

      console.log("showDateGrid--", startDate, endDate);
      const filter = { from: dateChange(startDate), to: dateChange(endDate) };
      const onLoad = false;
      getReportData(filter, onLoad, pageCount);
    }
  };

  const getReportData = async (filter, onLoad, pageCount) => {
    console.log(filter);
    if (filter.to === "") {
      const date = new Date();
      filter.to = dateChange(date);
    }
    try {
      const response = await getReport(filter, onLoad, pageCount, leadFilter);
      console.log("response --->", response.data.data.allLeads);
      if (response.data.status) {
        setPage(0);
        response.data.data.allLeads.length
          ? toast("Lead report fetch successfully")
          : toast("No match found");
        setReportData(response.data.data.allLeads);
        setCount(response.data.data.allLeads.length);
        setIsCompared(false);

        setReportFigures((prevState) => ({
          ...prevState,
          opportunity: response.data.data.opportunity
            ? response.data.data.opportunity
            : 0,
          close: response.data.data.close ? response.data.data.close : 0,
          drop: response.data.data.drop ? response.data.data.drop : 0,
          lead: response.data.data.lead ? response.data.data.lead : 0,
          prospect: response.data.data.prospect
            ? response.data.data.prospect
            : 0,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCountries = async () => {
    try {
      const response = await getIncludedCountries();
      if (response.data.status) {
        setLeadFilter((prevState) => ({
          ...prevState,
          country: [...response.data.data.filter((d) => d !== "")],
        }));
        setCountries(
          (prev) => (prev = response.data.data.filter((d) => d !== ""))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesOwners = async () => {
    try {
      const response =
        currentUser.role === "ADMIN" && (await getUsersByRoles(3));
      if (response.data.status) {
        [...response.data.data].map((sw) => {
          if (sw.active_status === true) {
            setLeadFilter((prevState) => {
              const temp = { ...prevState };
              temp.salesOwner.push(sw._id);
              return temp;
            });
          }
        });
        setSalesOwners(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function handleChartSwitch(pIndex, chartName) {
    setSelectChartName(chartName);
    let newFilterers = chartFilter.map(({ chartName }, index) => {
      return { chartName: chartName, checked: pIndex === index };
    });
    setChartFilter(newFilterers);
  }

  const handleCompare = async (event) => {
    let ownerInfo;
    // let ownerName;
    setIsCompared(event.target.checked);

    try {
      const [filterList, data] = await Promise.all([
        primaryComparetor === "Sales Owner"
          ? leadFilter.salesOwner
          : primaryComparetor === "Country"
          ? leadFilter.country
          : primaryComparetor === "Status"
          ? leadFilter.status
          : [],
        reportData,
      ]);

      const salesOwnerData = filterList.map((filterValue) => {
        const filteredData = data.filter((item) => {
          if (primaryComparetor === "Sales Owner") {
            return item.salesOwnerId === filterValue;
          } else if (primaryComparetor === "Country") {
            return item.country === filterValue;
          } else if (primaryComparetor === "Status") {
            return item.status.toLowerCase() === filterValue.toLowerCase();
          }
          return false;
        });

        const reportFigures = filteredData.reduce(
          (acc, curr) => {
            if (curr.status === "drop") {
              acc.drop++;
            } else {
              acc[curr.status.toLowerCase()]++;
            }
            return acc;
          },
          {
            opportunity: 0,
            close: 0,
            drop: 0,
            lead: 0,
            prospect: 0,
          }
        );

        // const ownerInfo = filteredData.length > 0 ? filteredData[0] : null;

        let ownerName = "";

        if (primaryComparetor === "Sales Owner") {
          console.log(filterValue, "mosalea");
          const owner = salesOwners.find((item) => item._id === filterValue);
          ownerName = owner ? owner.name : "";
        } else if (primaryComparetor === "Country") {
          const owner = countries.find(
            (item) => item.toLowerCase() === filterValue.toLowerCase()
          );
          ownerName = owner;
        } else if (primaryComparetor === "Status") {
          const owner = status.find(
            (item) => item.toLowerCase() === filterValue.toLowerCase()
          );
          ownerName = owner;
        }

        // ownerName = ownerInfo ? ownerInfo.salesOwner : "";
        //  const ownerInfo = salesOwners.find((item) => item._id === ownerId);
        //  const ownerName = ownerInfo ? ownerInfo.name : "";
        console.log(ownerName, "full");

        return {
          salesOwner: ownerName,
          reportFigures: reportFigures,
        };
      });

      setSalesOwnerFigure(salesOwnerData);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  console.log(salesOwnerFigure, "salesOwnerData2");
  console.log(reportFigures, "salesfigure");
  useEffect(() => {
    getAllCountries();
    getSalesOwners();
    if (currentUser && currentUser.role === "SALES") {
      setLeadFilter((prevState) => {
        const temp = { ...prevState };
        temp.salesOwner = [currentUser._id];
        return temp;
      });
    }
  }, []);
  const isLengthValid = (array, condition) => {
    return array.length === 2 || array.length === 3 || array.length === 4;
  };
  const isWidthLengthValid = (array) => array.length === 3;

  console.log(salesOwnerFigure, " myowners");

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            // sx={{ marginTop: "10px" }}
          >
            {/* <Card elevation={3} variant="outlined"> */}
            {/* <CardContent> */}
            {showReportAll ? (
              <ReportAll
                // after
                showChip={showChip}
                setShowChip={setShowChip}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                afterRemoveChip={afterRemoveChip}
                handleChange={handleChange}
                popup={popup}
                setPopup={setPopup}
                customDate={customDate}
                setCustomDate={setCustomDate}
                leadFilter={leadFilter}
                handleLeadFilter={handleLeadFilter}
                status={status}
                countries={countries}
                salesOwners={salesOwners}
                handleSalesOwnerForRender={handleSalesOwnerForRender}
                allChecked={allChecked}
                setAllChecked={setAllChecked}
                currentUser={currentUser}
                handleOnlyOneSalesOwner={handleOnlyOneSalesOwner}
                //before
                reportData={reportData}
                interval={interval}
                setInterval={setInterval}
                reportFigures={reportFigures}
                handleIntervals={handleIntervals}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
              />
            ) : (
              <ReportDrop
                reportData={reportData}
                handleChange={handleChange}
                interval={interval}
                setInterval={setInterval}
                reportFigures={reportFigures}
                popup={popup}
                setPopup={setPopup}
                showChip={showChip}
                setShowChip={setShowChip}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                handleIntervals={handleIntervals}
              />
            )}
            {/* </CardContent> */}
            {/* </Card> */}
          </Grid>
        </Grid>
        {reportData.length > 0 && (
          <Grid item xs={12} sx={{ marginTop: "30px" }}>
            <Card elevation={3} variant="outlined">
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={8.5} sx={{ alignSelf: "center" }}>
                    <Grid container spacing={1}>
                      {chartFilter.map(({ chartName, checked }, index) => {
                        return (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Chip
                              key={index}
                              variant={checked ? "filled" : "outlined"}
                              clickable
                              label={chartName}
                              color={checked ? "primary" : "default"}
                              onClick={() => {
                                handleChartSwitch(index, chartName);
                              }}
                              sx={{
                                border: checked
                                  ? "3px solid #3366FF"
                                  : "3px solid #fff",
                                borderRadius: "62px",
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3.5}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",

                      // border: " 1px solid black",
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <FormControl fullWidth sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-dateRange-select-label">
                          Primary Comparetor
                        </InputLabel>
                        <Select
                          // sx={{ maxHeight: "50px" }}
                          labelId="demo-dateRange-select-label"
                          id="demo-dateRange-select"
                          label="Primary Comparetor"
                          name="interval"
                          value={primaryComparetor}
                          onChange={handleComparetor}
                        >
                          {comparetorList.map((interval, index) => {
                            return (
                              <MenuItem key={index} value={interval}>
                                {interval}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      {/* <Tooltip title="Show comparision"> */}

                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isCompared}
                              onChange={handleCompare}
                              disabled={
                                primaryComparetor === "Sales Owner"
                                  ? !isLengthValid(leadFilter.salesOwner)
                                  : primaryComparetor === "Country"
                                  ? !isLengthValid(leadFilter.country)
                                  : primaryComparetor === "Status"
                                  ? !isLengthValid(leadFilter.status)
                                  : true // Default case, disabled if no specific logic defined
                              }
                              name="jason"
                            />
                          }
                          label="Compare"
                        />
                        <FormHelperText>
                          {!isCompared
                            ? "*Atleast select 2 to 4 for compare"
                            : null}
                        </FormHelperText>
                      </FormGroup>
                      {/* </Tooltip> */}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!isCompared ? (
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ marginTop: "-2px" }}>
              {reportData.length > 0 ? (
                <Card elevation={3} variant="outlined" sx={{ marginTop: 3 }}>
                  <CardContent sx={{ marginTop: 2 }}>
                    {selectChartName === "Pie Chart" ? (
                      <Nivopie
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    ) : selectChartName === "Bar Chart" ? (
                      <Nivobar
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    ) : (
                      <Nivoline
                        reportFigures={reportFigures}
                        reportData={reportData}
                      />
                    )}
                  </CardContent>
                </Card>
              ) : (
                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <Typography
                    textAlign="center"
                    fontWeight="700"
                    color="#857C7A"
                  >
                    {`Please fetch all the records to show charts`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            justifyContent={
              (primaryComparetor === "Sales Owner" &&
                isWidthLengthValid(leadFilter.salesOwner)) ||
              (primaryComparetor === "Country" &&
                isWidthLengthValid(leadFilter.country)) ||
              (primaryComparetor === "Status" &&
                isWidthLengthValid(leadFilter.status))
                ? "center"
                : undefined
            }
          >
            {reportData.length > 0 ? (
              salesOwnerFigure.length &&
              salesOwnerFigure.map(({ salesOwner, reportFigures }) => (
                <Grid
                  xs={
                    leadFilter.salesOwner.length === 2
                      ? 6
                      : leadFilter.salesOwner.length === 3
                      ? 6
                      : 6
                  }
                  sx={{
                    width:
                      (primaryComparetor === "Sales Owner" &&
                        isWidthLengthValid(leadFilter.salesOwner)) ||
                      (primaryComparetor === "Country" &&
                        isWidthLengthValid(leadFilter.country)) ||
                      (primaryComparetor === "Status" &&
                        isWidthLengthValid(leadFilter.status))
                        ? 1 / 2
                        : undefined,
                  }}
                  item
                >
                  <Card elevation={3} variant="outlined" sx={{ marginTop: 3 }}>
                    <CardContent sx={{ m: 1 }}>
                      {selectChartName === "Pie Chart" ? (
                        <Nivopie
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          primaryComparetor={primaryComparetor}
                        />
                      ) : selectChartName === "Bar Chart" ? (
                        <Nivobar
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          primaryComparetor={primaryComparetor}
                          salesOwnerFigure={salesOwnerFigure}
                        />
                      ) : (
                        <Nivoline
                          reportFigures={reportFigures}
                          reportData={reportData}
                          isCompared={isCompared}
                          countries={leadFilter.country}
                          selectOwnerList={leadFilter.salesOwner}
                          OwnersNameList={salesOwners}
                          salesOwnerName={salesOwner}
                          status={leadFilter.status}
                          primaryComparetor={primaryComparetor}
                          salesOwnerFigure={salesOwnerFigure}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ marginTop: 4 }}>
                <Typography textAlign="center" fontWeight="700" color="#857C7A">
                  {`Please fetch all the records to show charts`}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default Reports;
