import {
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Select,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";

// summary: "",
//     skills: "",
//     description: "",
//     files: [],

import React, { useState } from "react";
import InputField from "../Unused/shared/input.field";
import Editor from "../texteditor/Editor";
import "./Lead.create.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createLead } from "../../services/leads.service";
import Fileupload from "../Fileupload/index";
import { getUsersByRoles } from "../../services/users.service";
import { getTechStacks } from "../../services/projects.service";
import EntityAttributeFields from "../Shared/Custom/EntityAttributeFields";

const TAG = "lead.create";

let toastId = null;
function LeadCreateComponent({
  showButton,
  setLead,
  lead,
  refreshEditor,
  toEdit,
  customAttributes,
  setCustomAttributes,
  afterCreate,
}) {
  const notify = () => toast("Sucessfully Added");
  const [files, addFile] = useState([]);
  // const [reqSummary, setReqSummary] = React.useState(null);
  // const [skills, setSkills] = React.useState(null);
  // const [details, setDetails] = React.useState(null);
  const [leadObj, SetLeadObj] = useState([]);
  const [userType, setUserType] = useState([]);
  const [showAddSkillDialog, setShowAddSkillDialog] = React.useState(false);
  const [techStacks, setTechStacks] = useState([]);
  const [skillName, setSkillName] = useState("");

  const handleShowAddSkillDialog = () => {
    setShowAddSkillDialog(true);
  };

  const handleHideAddSkillDialog = () => {
    setShowAddSkillDialog(false);
  };

  const handleClose = () => {
    setShowAddSkillDialog(false);
  };

  const handleAddSkill = () => {
    setLead((prevState) => ({
      ...prevState,
      skills: [...prevState.skills, skillName],
    }));
    // UpdateProject();
    if (skillName.length === 0) {
      toast("Skill name cannot be empty");
    } else {
      setSkillName("");
      handleClose();
    }
  };

  const handleEmptySkills = (e) => {
    console.log(e.target.value);
    if (e.target.value !== "") {
      setSkillName(e.target.value);
    }
  };

  const handleEmptySkillsChip = (val) => {
    val.map((element, index) => {
      if (element.name.length > 0) {
        return element;
      }
    });
  };

  const addNewSkillAndShowChip = () => {
    console.log("Inside button click", skillName);
    if (skillName !== "") {
      setLead((prevState) => ({
        ...prevState,
        skills: [...prevState.skills, { name: skillName }],
      }));
      setSkillName("");
    }
    // UpdateProject();
    handleHideAddSkillDialog();
  };

  const fetchTechStacks = async () => {
    try {
      const response = await getTechStacks();
      if (response.data.status) {
        setTechStacks(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getUsersByRoles(2).then((res) => {
      console.log("role data", res.data);
      setUserType(res.data.data);
    });
    fetchTechStacks();
  }, []);

  const fileSelectionHandler = (e) => {
    console.log("file.details: ", e.target.value);
    addFile((pState) => {
      pState.push(e.target.value);
      console.log("file.array: ", files);
      return pState;
    });
  };

  console.log("Nov 8", lead);
  const handleChange = (e) => {
    if (e.target.value.length > 80) {
      if (toastId == null) {
        toastId = toast.warning(
          "Summary should not be more then 80 characters."
        );
        console.log(toastId);
      } else {
        toast.update(toastId, "Summary should not be more then 80 characters.");
      }
      //  toast.warning("Summary should not be more then 80 characters.");
    } else {
      setLead((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      console.log("Lead data", lead);
    }
  };

  return (
    <Card elevation={3} variant="outlined">
      <CardContent>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12} sm={12}>
              <Typography variant="h4" color="primary">
                Requirement Details
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <TextField
                label="Summary"
                variant="outlined"
                placeholder="Summary"
                type="text"
                name="summary"
                required
                value={lead.summary}
                fullWidth
                onChange={handleChange}
                // inputProps={{ maxLength: 2 }}
                // helperText="It should not cross more than 80 character*"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <Autocomplete
                  readOnly={toEdit ? true : false}
                  multiple
                  freeSolo
                  id="tags-outlined"
                  options={techStacks}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => {
                    return (
                      <Grid container spacing={0}>
                        {option.name === "+ Add" ? (
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                size: "large",
                              }}
                              onClick={handleShowAddSkillDialog}
                            >
                              ADD NEW
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <li {...props} style={{ borderColor: "white" }}>
                              {option.name}
                            </li>
                          </Grid>
                        )}
                      </Grid>
                    );
                  }}
                  value={
                    lead.skills > 0
                      ? handleEmptySkillsChip(lead.skills)
                      : lead.skills
                  }
                  filterSelectedOptions
                  onChange={(event, newValue, reason) => {
                    console.log(`${TAG}.onChange: `, event, newValue);

                    const hasDuplicates = (newSkills) => {
                      const skillCounts = newSkills.reduce((acc, skill) => {
                        const skillName = skill.name.toLowerCase();
                        acc[skillName] = (acc[skillName] || 0) + 1;
                        return acc;
                      }, {});
                      console.log(skillCounts, "skillcount");
                      return Object.values(skillCounts).some(
                        (count) => count > 1
                      );
                    };
                    function setChips(newValueArray) {
                      if (hasDuplicates(newValueArray)) {
                        toast.warn(
                          "You cannot add the same skills, please choose a new one"
                        );
                        return;
                      }
                      setLead((prevState) => ({
                        ...prevState,
                        skills: [...newValueArray],
                      }));
                    }
                    switch (reason) {
                      case "createOption":
                        console.log("");
                        newValue.forEach((e) => {
                          if (typeof e === "string" && e.trim() !== "") {
                            let newData = { name: e };
                            newValue.pop(e);
                            setChips([...newValue, newData]);
                          }
                        });
                        break;
                      case "removeOption":
                      case "selectOption":
                      case "clear":
                        setChips(newValue);
                        break;
                      default:
                        break;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Skills"
                      placeholder="Click to Add"
                    />
                  )}
                ></Autocomplete>
                <span
                  style={{
                    fontSize: "10px",
                    marginLeft: "8px",
                    color: "#808080",
                  }}
                >
                  *To Add An Unregistered Chip, Write Its Name & Hit Enter
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* techowner */}
              <Box>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-sales-owner-label">
                    Tech Owner
                  </InputLabel>
                  <Select
                    // disabled={toEdit}
                    labelId="demo-simple-select-sales-owner-label"
                    id="demo-simple-select-sales-owner"
                    label="techOwner"
                    name="techOwner"
                    value={lead.techOwner}
                    onChange={handleChange}
                  >
                    <MenuItem value="">SELECT</MenuItem>
                    {userType?.map((e) => {
                      if (e.active_status === true) {
                        return (
                          <MenuItem key={e._id} value={e._id}>
                            {e.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h4">Details</Typography>
            </Grid>
            <Grid item>
              <Editor
                style={{
                  width: "100%",
                  fontSize: "24px",
                  marginTop: 6,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingRight: 12,
                  paddingLeft: 12,
                  border: "1px solid",
                  borderRadius: 10,
                }}
                lead={lead}
                setLead={setLead}
                callback={(det) => {
                  setLead((prevState) => ({
                    ...prevState,
                    description: det,
                  }));
                }}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          {customAttributes?.length ? (
            <Grid container spacing={4} mt="2px">
              {customAttributes.map((attribute, index) => (
                <Grid item xs={12} key={index}>
                  <EntityAttributeFields
                    componentIndex={index}
                    componentDeatils={attribute}
                    allAttributes={customAttributes}
                    setterFunction={setCustomAttributes}
                    entityName={"Lead"}
                    afterLeadCreate={afterCreate}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <Dialog open={showAddSkillDialog} onClose={handleClose}>
        <DialogTitle>Add</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new skills write its name in the below textbox
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="skills"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleEmptySkills(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            CANCEL
          </Button>
          <Button onClick={addNewSkillAndShowChip}>ADD</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default LeadCreateComponent;
