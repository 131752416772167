import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

function CostDistributingListing({
  costAndResources,
  index,
  fieldHandler,
  requirements,
}) {
  const modes = ["Hourly", "Daily", "Monthly", "Yearly"];

  let toastId = null;
  return (
    <Stack direction="row" spacing={2}>
      {/* <TextField
        sx={{ width: "30%" }}
        label="Payment cycle"
        placeholder="payment cycle"
        name="cost"
        type="number"
        value={costAndResources?.cost}
        onChange={(event) => {
          if (event.target.value < 0 || event.target.value.length > 14) {
            toast.warn("Cost should not cross 14 digit");
            return;
          }
          fieldHandler(event, index, "cost");
        }}
      /> */}
      {requirements.mode != "fixed" && requirements.tmRevenues.length ? (
        <FormControl
          sx={{
            width: "35%",
          }}
        >
          <InputLabel>Payment Cycle</InputLabel>
          <Select
            label="payment Cycle"
            name="paymentCycle"
            value={costAndResources?.paymentCycle}
            onChange={(event) => {
              fieldHandler(event, index, "paymentCycle");
            }}
          >
            {modes.length
              ? modes.map((mode, index) => {
                  return (
                    <MenuItem key={index} value={mode}>
                      {mode}
                    </MenuItem>
                  );
                })
              : null}
            {/* <MenuItem key={index} value={`hourly`}>
              hourly
            </MenuItem> */}
          </Select>
        </FormControl>
      ) : null}
      {requirements.mode != "fixed" && requirements.tmRevenues.length ? (
        <TextField
          sx={{ width: "30%" }}
          label="Resource Name"
          placeholder="Resource Name"
          name="resourceName"
          // type="text"
          value={costAndResources?.resourceName}
          onChange={(event) => {
            if (event.target.value < 0 || event.target.value.length > 20) {
              toast.warn("Resource name should not cross 14 characters");
              return;
            }
            fieldHandler(event, index, "resourceName");
          }}
        />
      ) : (
        <TextField
          sx={{ width: "30%" }}
          label="Milestone Name"
          placeholder="Milestone Name"
          name="milestoneName"
          // type="text"
          value={costAndResources?.milestoneName}
          onChange={(event) => {
            if (event.target.value < 0 || event.target.value.length > 20) {
              toast.warn("Milestone name should not cross 14 characters");
              return;
            }
            fieldHandler(event, index, "milestoneName");
          }}
        />
      )}

      <TextField
        sx={{ width: "30%" }}
        label="Cost"
        placeholder="Cost"
        name="cost"
        type="number"
        value={costAndResources?.cost}
        onChange={(event) => {
          if (event.target.value < 0 || event.target.value.length > 14) {
            toast.warn("Cost should not cross 14 digit");
            return;
          }
          fieldHandler(event, index, "cost", requirements.mode);
        }}
      />
      <TextField
        sx={{ width: "70%" }}
        label="Invoice Description"
        placeholder="Invoice Description"
        name="invoiceDescription"
        value={costAndResources?.invoiceDescription}
        onChange={(event) => {
          if (event.target.value && event.target.value.length > 150) {
            toast.warning(
              "Invoice Description should not be more then 150 characters."
            );
            return;
          } else {
            fieldHandler(event, index, "invoiceDescription", requirements.mode);
          }
        }}
      />
    </Stack>
  );
}

export default CostDistributingListing;
