import React, { useContext, useEffect, useState } from "react";
import {
  getContactById,
  getComments,
  updateWatcher,
  getActionDoersByContactId,
  getSourceList,
} from "../../services/contacts.service";
import {
  Grid,
  Card,
  Box,
  Button,
  Typography,
  Stack,
  Container,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import Page from "../../components/UI/Page";
import Info from "../../components/Contacts/Info";
import LeadRow from "../../components/Lead/Lead.row";
import ActionSection from "../../components/Shared/Action/ActionSection";
import { ToastContainer, toast } from "react-toastify";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CommentSection from "../../components/Shared/Comments/CommentSection";
import { isEditable } from "@testing-library/user-event/dist/utils";
import LeadCreateComponent from "../../components/Lead/Lead.create";
import { createLead } from "../../services/leads.service";
import EventBus from "../../components/texteditor/EventBus";
import constants from "../../constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "../../context/auth.context";
import { getUserById, getUsersByRoles } from "../../services/users.service";
import ContactWatcher from "../../components/Contacts/contact-watcher";
import { useMatchCollectionName } from "../../utils/filterObject";
import { useDynamicContactTitle } from "./Contacts";
import { useDynamicLeadTitle } from "../Leads/Leads";
import getAttributesByEntity from "../../services/entity.attribute.services";
import FileUpload from "../../components/Fileupload";
let hitApi = true;

const ContactView = () => {
  const [sourceData, setSourceData] = useState([]);
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  console.log(currentUser);
  const [contactInfoObj, setContactInfoObj] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();

  const { id } = useParams();
  console.log("id", id);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [customLeadAttributes, setCustomLeadAttributes] = useState([]);

  const initialContactState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    alternateContact: "",
    address: "",
    country: "",
    linkedIn: "",
    source: {
      name: "",
    },
    salesOwner: "",
    watchers: [],
    attributes: [],
  };
  const TAG = "Contact.view.js";
  let initialLeadState = {
    summary: "",
    skills: [],
    techOwner: "",
    status: "lead",
    description: "",
    files: [],
  };
  //TODO: Remove the curly braces
  const [lead, setLead] = React.useState(initialLeadState);

  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isViewDocuments, setIsViewDocuments] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalOpen = (fromViewDoc) => {
    setModalOpen(true);
    setIsViewDocuments(fromViewDoc);
  };

  const handleModalClose = () => {
    setDescriptionObj({});
    setFiles((prev) => {
      return isViewDocuments ? prev : (prev = []);
    });
    setModalOpen(false);
    setIsViewDocuments(false);
  };

  const [contact, setContact] = React.useState(initialContactState);

  const [toEdit, setToEdit] = React.useState(true);

  const [fromWatcherList, setFromWatcherList] = useState(false);

  const [iactionedApiCalled, setIactionedApiCalled] = useState(false);

  const initialActionState = {
    toDo: "",
    doer: "",
    assignedBy: currentUser._id,
    // dueDate: new Date(new Date().setHours(0, 0, 0, 0)),
    dueDate: new Date(),
    meetLink: "",
    // dueTime: new Date(),
  };
  const [checked, setChecked] = React.useState(false);

  console.log(initialActionState.dueDate);
  const [action, setAction] = React.useState(initialActionState);

  const initialCommentState = {
    comment: "",
    createdBy: "",
  };

  const [isLeadFetched, setIsLeadFetched] = React.useState(false);

  const [comment, setComment] = React.useState(initialCommentState);
  const [saveButton, setSaveButton] = useState(false);
  const [updateContactCalled, setUpdateContactCalled] = useState(false);

  const [accessDeniedToWatcher, setAccessDeniedToWatcher] = useState(false);
  const [descriptionObj, setDescriptionObj] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  const validate = (data) => {
    return data === "" || data === undefined || data === null ? false : true;
  };

  const attributeValidateFunction = (customAttributes) => {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }
      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  };

  const addLead = async (e) => {
    if (!validate(lead.summary)) {
      toast("Summary can not be empty");
      hitApi = false;
    }
    if (!validate(lead.techOwner)) {
      toast("Tech Owner can not be empty");
      hitApi = false;
    }
    if (validate(lead.summary) && validate(lead.techOwner)) {
      hitApi = true;
    }
    const attributeValidateLead =
      attributeValidateFunction(customLeadAttributes);
    if (!attributeValidateLead) {
      return;
    }
    try {
      setSaveButton(true);
      const temp = { ...lead };
      delete temp.files;
      temp.skillsArray = temp.skills;
      delete temp.skills;
      const response = hitApi
        ? await createLead(id, {
            ...temp,
            attributes: [...customLeadAttributes],
          })
        : null;

      if (response.data.status) {
        console.log(temp);
        setIsLeadFetched(false);
        toast(
          `${leadDynamicTitle?.displayName.singularName} added successfully`
        );
        setLead(initialLeadState);
        EventBus.dispatch("EditorBus");
        handleClose();
        setSaveButton(false);
      }
    } catch (error) {
      console.log(error);
      setSaveButton(false);
    }
  };

  const [isEditPage] = React.useState(true);

  const [TempContact, setTempContact] = React.useState(initialContactState);

  const [loadedComments, setLoadedComments] = useState([]);

  const [loadedActions, setLoadedActions] = useState([]);

  const [watcherDialog, setWatcherDialog] = useState(false);
  const [isWatcherDialogOnEdit, setIsWatcherDialogOnEdit] = useState(false);

  const [users, setUsers] = useState([]);

  const [salesOwnerDetails, setSalesOwnerDetails] = useState({
    name: "",
  });
  const param = window.location.pathname;
  const pathArray = param.split("/");
  let matchedCollection = useMatchCollectionName(pathArray[1]);
  const [allDoers, setAllDoers] = useState([]);

  const getAllUsers = async () => {
    try {
      const response = await getUsersByRoles(1);
      if (response.data.status) {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesOwner = async () => {
    try {
      const response = await getUserById(contact.salesOwner, false);
      if (response.data.status) {
        console.log("-----Sales Owner Info", response.data.data);
        setSalesOwnerDetails((prev) => ({
          ...prev,
          name: response.data.data.name,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getActionDoersUsersByContactId = async () => {
    try {
      const response = await getActionDoersByContactId(id);
      if (response.data.status) {
        // getAllUsers();
        setAllDoers((prev) => [
          // ...prev,
          ...response.data.data[0].actionDoers,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filter = () => {
    setUsers((prev) => {
      const temp = [...prev];
      for (let index = 0; index < temp.length; index++) {
        for (let index1 = 0; index1 < allDoers.length; index1++) {
          console.log(temp[index], allDoers[index1]);
          if (temp[index].name === allDoers[index1].name) {
            temp.splice(index, 1);
          }
        }
      }
      console.log(temp);
      return temp;
    });
    console.log(users);
  };
  const submitDocComment = async () => {
    // setFromCommentCreate(false);
    setModalOpen(false);
  };

  useEffect(() => {
    console.log(allDoers);
    getAllUsers();
    // filter();
  }, [allDoers]);

  const closeWatcherDialog = () => {
    setWatcherDialog(false);
  };

  const convertAgainToReadOnly = () => {
    setIsWatcherDialogOnEdit(false);
  };

  const contactWatchers = () => {
    let arr = [];
    contactInfoObj.watchers.map((e, i) => {
      arr.push({ platformUser: e._id });
    });
    console.log("arrrr---->", arr);
    return arr;
  };

  const CheckAndGetChangedLeadDataWatchers = () => {
    console.log(
      "-----> Lead Watcher",
      lead.watchers,
      "----->templead watchers",
      TempContact.watchers
    );
    const result =
      JSON.stringify(contactInfoObj.watchers) ===
      JSON.stringify(TempContact.watchers)
        ? false
        : true;
    return result;
  };

  const updateWatchers = async () => {
    console.log("Changed One", contactInfoObj.watchers);
    console.log("Temp", TempContact.watchers);
    try {
      const isChanged = CheckAndGetChangedLeadDataWatchers();
      console.log("isChnage---->", isChanged);
      if (contactInfoObj.watchers.length) {
        if (isChanged) {
          const response = await updateWatcher(id, {
            watchers: contactWatchers(),
          });
          if (response.data.status) {
            closeWatcherDialog();
            toast("Watcher Updated Successfully");
            convertAgainToReadOnly();
            setUpdateContactCalled(false);
          }
        } else {
          toast("Nothing to update");
        }
      } else {
        if (isChanged) {
          const response = await updateWatcher(id, {
            watchers: contactWatchers(),
          });

          if (response.data.status) {
            closeWatcherDialog();
            toast("Watcher Removed Successfully");
            convertAgainToReadOnly();
            setUpdateContactCalled(false);
          }
        } else {
          toast("Nothing to update");
        }
      }
    } catch (error) {
      closeWatcherDialog();
      console.log(error);
      toast("Something Went Wrong");
    }
  };
  const getSourceLeadList = async (company) => {
    console.log("company", company);
    const result = await getSourceList(company && company);

    if (result) {
      setSourceData(result.data.data);
    }
  };

  const getLeadCustomAttributes = async () => {
    try {
      const response = await getAttributesByEntity("Leads");
      if (response.data.status) {
        setCustomLeadAttributes(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isLeadFetched)
      getContactById(id)
        .then((response) => {
          if (response.data.status) {
            console.log("Hi");
            // console.log(`${TAG}.etsavebutton.tempObject`, temp, contact);
            console.log(`${TAG}.response.data.data----`, response.data.data);

            //Watchers
            response.data.data.watchers.forEach((element) => {
              if (element.role !== "ADMIN") {
                if (element._id === currentUser._id)
                  setAccessDeniedToWatcher(true);
              }
            });

            setCustomAttributes(
              JSON.parse(JSON.stringify(response.data.data.attributes))
            );

            setContactInfoObj(response.data.data);
            getSourceLeadList(response.data.data.company);
            console.log(
              "response.data.data.company",
              response.data.data.company
            );
            const keys = Object.keys(initialContactState);
            console.log("Keys---", keys);
            const temp = {};
            keys.forEach((key) => {
              // if (key === "source" || key === "company") {
              if (key === "source") {
                temp[key] = { name: response.data.data[key] };
              } else {
                temp[key] = response.data.data[key];
              }
            });
            // temp.source["name"] = temp.source;

            setContact(temp);
            setTempContact(temp);
            setIsLeadFetched(true);
            console.log(contact);
          } else {
            console.log("Unauthorized");
            navigate(`/unauthorized`);
          }

          // setLead({ ...lead, ...response.data.data.lead });
        })
        .catch((error) => {
          console.error(error);
          console.log(error.response.data, "Unauthorized in catch");
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });

    getAllUsers();
    getSalesOwner();
    getActionDoersUsersByContactId();
    getLeadCustomAttributes();
    // filter()
  }, [isLeadFetched]);

  // THIS USEEFFECT is for when we click the contact name
  //  in sourclist dialouge we redirect to that contact detais page
  useEffect(() => {
    setIsLeadFetched(false);
  }, [id]);

  useEffect(() => {
    if (!updateContactCalled) {
      getContactById(id)
        .then((response) => {
          if (response.data.status) {
            console.log("Hi");
            // console.log(`${TAG}.etsavebutton.tempObject`, temp, contact);
            console.log(`${TAG}.response.data.data----`, response.data.data);

            //Watchers
            response.data.data.watchers.forEach((element) => {
              if (element.role !== "ADMIN") {
                if (element._id === currentUser._id)
                  setAccessDeniedToWatcher(true);
              }
            });

            setCustomAttributes(
              JSON.parse(JSON.stringify(response.data.data.attributes))
            );
            setContactInfoObj(response.data.data);
            const keys = Object.keys(initialContactState);
            console.log("Keys---", keys);
            const temp = {};
            keys.forEach((key) => {
              // if (key === "source" || key === "company") {
              if (key === "source") {
                temp[key] = { name: response.data.data[key] };
              } else {
                temp[key] = response.data.data[key];
              }
            });
            // temp.source["name"] = temp.source;

            setContact(temp);
            setTempContact(temp);
            setIsLeadFetched(true);
            console.log(contact);
            setUpdateContactCalled(true);
          } else {
            console.log("Unauthorized");
            navigate(`/unauthorized`);
          }

          // setLead({ ...lead, ...response.data.data.lead });
        })
        .catch((error) => {
          console.log(error.response.data, "Unauthorized in catch");
          console.error(error);
          if (error.response.data.code == 401) {
            navigate(`/unauthorized`);
          }
        });

      getAllUsers();
    }
  }, [updateContactCalled]);
  const contactDynamicTitle = useDynamicContactTitle();
  const leadDynamicTitle = useDynamicLeadTitle();

  return (
    <Page>
      <Container>
        <ToastContainer />
        <Grid container spacing={4}>
          <Grid item>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={11.5} sm={11.5}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {`${contactDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                    </Typography>
                  </Grid>
                  {/* {<Grid item xs={0.5}>
                    <Tooltip title="You Can Simply Add Watchers Here">
                      <IconButton
                        color="primary"
                        onClick={() => { setWatcherDialog(true); filter() }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>} */}

                  <Grid item>
                    <Info
                      contact={contact}
                      customAttributes={customAttributes}
                      setCustomAttributes={setCustomAttributes}
                      setContact={setContact}
                      toEdit={toEdit}
                      setToEdit={setToEdit}
                      id={id}
                      canEdit={isEditPage}
                      isEditPage={isEditPage}
                      TempContact={TempContact}
                      fromCreatePage={false}
                      setTempContact={setTempContact}
                      setUpdateContactCalled={setUpdateContactCalled}
                      salesOwnerInfoCall={() => getSalesOwner()}
                      filterUsers={() => filter()}
                      sourceData={sourceData}
                      fromContactViePage={true}
                      getSourceLeadList={getSourceLeadList}
                      setIactionedApiCalled={setIactionedApiCalled}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3}>
                    <Typography variant="h5" gutterBottom color="primary">
                      {leadDynamicTitle?.displayName.singularName}
                    </Typography>
                  </Grid>
                  {currentUser._id === contact.salesOwner ||
                  currentUser.role === "ADMIN" ? (
                    <Grid
                      item
                      xs={9}
                      sx={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        startIcon={<AddIcon />}
                        onClick={handleClickOpen}
                      >
                        Add
                      </Button>
                      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
                        <DialogTitle>REQUIREMENT DETAILS</DialogTitle>
                        <DialogContent>
                          <LeadCreateComponent
                            lead={lead}
                            setLead={setLead}
                            customAttributes={customLeadAttributes}
                            setCustomAttributes={setCustomLeadAttributes}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button color="error" onClick={handleClose}>
                            CANCEL
                          </Button>
                          <Button disabled={saveButton} onClick={addLead}>
                            SAVE
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={12}>
                    <LeadRow
                      contactInfoObj={contactInfoObj}
                      leadDynamicTitle={
                        leadDynamicTitle?.displayName.singularName
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      ACTIONS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ActionSection
                      contactInfoObj={contactInfoObj}
                      action={action}
                      setAction={setAction}
                      initialActionState={initialActionState}
                      isEditPage={isEditPage}
                      loadedActions={loadedActions}
                      setLoadedActions={setLoadedActions}
                      actionType={constants.CONTACTS}
                      id={id}
                      checked={checked}
                      setChecked={setChecked}
                      getAllDoers={() => {
                        getActionDoersUsersByContactId();
                      }}
                      accessDeniedToWatcher={accessDeniedToWatcher}
                      fromContactPage={true}
                      currentUser={currentUser}
                      allDoers={allDoers}
                      iactionedApiCalled={iactionedApiCalled}
                      setIactionedApiCalled={setIactionedApiCalled}
                      state={state}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card
              container
              sx={{ backgroundColor: "#f1f2f6" }}
              elevation={5}
              variant="outlined"
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom color="primary">
                      COMMENTS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CommentSection
                      contact={contact}
                      contactInfoObj={contactInfoObj}
                      comment={comment}
                      setComment={setComment}
                      loadedComments={loadedComments}
                      setLoadedComments={setLoadedComments}
                      initialCommentState={initialCommentState}
                      commentType={constants.CONTACTS}
                      id={id}
                      salesOwner={contact.salesOwner}
                      fromContactPage={true}
                      accessDeniedToWatcher={accessDeniedToWatcher}
                      currentUser={currentUser}
                      allDoers={allDoers}
                      setIactionedApiCalled={setIactionedApiCalled}
                      handleClickOpen={handleModalOpen}
                      handleClose={handleModalClose}
                      descriptionObj={descriptionObj}
                      files={files}
                      setDescriptionObj={setDescriptionObj}
                      setFiles={setFiles}
                      setLoaderDialog={setLoaderDialog}
                    />
                  </Grid>
                </Grid>
                <Dialog
                  maxWidth="md"
                  open={modalOpen}
                  onClose={handleModalClose}
                >
                  <DialogContent>
                    <FileUpload
                      files={files}
                      setFiles={setFiles}
                      descriptionObj={descriptionObj}
                      setDescriptionObj={setDescriptionObj}
                      fromContactViewPage={true}
                      currentUser={currentUser}
                      contact={contact}
                      isFromComment={true}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleModalClose}>Cancel</Button>
                    <Button disabled={saveButton} onClick={submitDocComment}>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>
            <Dialog open={loaderDialog}>
              <DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "20px",
                }}
              >
                Loading... Please Wait
              </DialogActions>
            </Dialog>
          </Grid>

          <Dialog open={watcherDialog} fullWidth>
            <DialogTitle>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={11.25}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span>Watcher List:</span>
                </Grid>
                <Grid item xs={0.75}>
                  <IconButton onClick={closeWatcherDialog}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1} sx={{ marginTop: "2px" }}>
                <Grid item xs={isWatcherDialogOnEdit ? 10.5 : 11}>
                  <ContactWatcher
                    currentUser={currentUser}
                    isWatcherDialogOnEdit={isWatcherDialogOnEdit}
                    setIsWatcherDialogOnEdit={setIsWatcherDialogOnEdit}
                    users={users}
                    contactInfoObj={contactInfoObj}
                    setContactInfoObj={setContactInfoObj}
                    salesOwnerDetails={salesOwnerDetails}
                    setSalesOwnerDetails={setSalesOwnerDetails}
                  />
                </Grid>
                <Grid
                  item
                  xs={isWatcherDialogOnEdit ? 1.5 : 1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {!isWatcherDialogOnEdit ? (
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setIsWatcherDialogOnEdit(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setIsWatcherDialogOnEdit(false);
                          // setContact({ ...TempContact });
                          const temp = { ...contactInfoObj };
                          temp.watchers = TempContact.watchers;
                          setContactInfoObj(temp);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={updateWatchers}>
                        <SaveIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingRight: "20px", paddingBottom: "10px" }}>
              {/* {<Button color="primary" variant="contained" onClick={updateWatchers}>SAVE</Button>} */}
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    </Page>
  );
};

export default ContactView;
