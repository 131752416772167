import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import DecidingFactor from "./DecidingFactor";
import CostDistributingListing from "./CostDistributingListing";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EntityAttributeFields from "../../Shared/Custom/EntityAttributeFields";
import { useDynamicProjectTitle } from "../../../pages/Projects/Projects";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ProjectStaticAttribute from "./ProjectStaticAttribute";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { toast } from "react-toastify";

function CostAssignmentDialog({
  requirements,
  setHomeCompany,
  setHomeAccount,
  requirementSetterFuntion,
  handleIncludeGst,
  onCancelLeadStatusChange,
  handleAdd,
  lead,
  projectData,
  setProjectData,
  projectSkills,
  setProjectSkills,
  projectTechStacks,
  setProjectTechStacks,
  customAttributes,
  setCustomAttributes,
  fromLeadPage,
  parentCompanyDetails,
}) {
  // const costModes = ["Monthly", "Daily", "Hourly", "Fixed"];
  const costModes = ["Time & Material", "Fixed"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = [
    "Select Cost Estimation",
    "Select Project Static Attibutes",
    "Select Project Custom Attributes",
  ];

  const [toEdit] = React.useState(false);
  console.log(requirements, "req");
  const projectDynamicTitle = useDynamicProjectTitle();

  const handleCostAssignmentDialogState = (event, companyDetails) => {
    // if (
    //   (event.target.name === "totalCost" && event.target.value < 0) ||
    //   event.target.value.length > 14
    // ) {
    //   toast.warn("Cost should not cross 14 digit");
    //   return;
    // }
    if (event.target.name === "totalCost" && event.target.value.length > 14) {
      toast.warn("Cost should not cross 14 digit");
      return;
    }
    if (event.target.name === "gstIncluded") {
      console.log(event.target.checked, event.target.name, "newcost");
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.checked,
      }));
    }
    if (event.target.name === "company") {
      console.log(event.target.value, event.target.name, "event value");

      // const { id, name, country } = event.target.value;
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      companyDetails.find((elem, i) => {
        if (event.target.value === elem.name) {
          setHomeCompany(elem.id);
          return elem;
        }
        // return elem;
      });
    }
    if (event.target.name === "account") {
      console.log(event.target.value, event.target.name, "event value");

      // const { id, name, country } = event.target.value;
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      companyDetails.find((elem, i) => {
        if (event.target.value === elem.name) {
          setHomeAccount(elem.id);
          return elem;
        }
        // return elem;
      });
    }
    if (event.target.name === "numberofResources") {
      if (event.target.value > 20) {
        toast.warn("Number of resource should not be greate than 20");
        return;
      }

      requirements.handleRevinew(parseInt(event.target.value));
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }
    if (event.target.name === "currency") {
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
      if (!parentCompanyDetails?.country && event.target.value === "INR (₹)") {
        requirementSetterFuntion((prev) => ({
          ...prev,
          gstApplicable: true,
        }));
      }
    } else {
      requirementSetterFuntion((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    }
  };
  const handleCostAndInvoiceDescription = (event, index, fieldName, mode) => {
    // let regex = /^[a-zA-Z ]*$/;
    let regex = /^[a-zA-Z0-9 ]*$/;
    switch (fieldName) {
      case "paymentCycle":
        requirementSetterFuntion((prev) => {
          const temp = { ...prev };
          temp.tmRevenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "resourceName":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warning("Resource name can not contain only space");
          return;
        }
        const Rvalue = regex.test(event.target.value);
        if (!Rvalue) {
          toast.warn("Special characters are not allowed");
          return;
        }
        // if (/\d/.test(event.target.value)) {
        //   toast.warn("Resource name cannot contain numbers");
        //   return;
        // }

        requirementSetterFuntion((prev) => {
          const temp = { ...prev };
          temp.tmRevenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "milestoneName":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warning("Milestone name can not contain only space");
          return;
        }
        const Mvalue = regex.test(event.target.value);
        if (!Mvalue) {
          toast.warn("Special characters are not allowed");
          return;
        }
        // if (/\d/.test(event.target.value)) {
        //   toast.warn("Milestone name cannot contain numbers");
        //   return;
        // }
        requirementSetterFuntion((prev) => {
          const temp = { ...prev };
          temp.revenues[index][fieldName] = event.target.value;
          return temp;
        });
        break;
      case "cost":
        if (mode === "fixed") {
          requirementSetterFuntion((prev) => {
            const temp = { ...prev };
            temp.revenues[index][fieldName] = event.target.value;
            return temp;
          });
        } else {
          requirementSetterFuntion((prev) => {
            const temp = { ...prev };
            temp.tmRevenues[index][fieldName] = event.target.value;
            return temp;
          });
        }
        break;

      case "invoiceDescription":
        if (event.target.value && !event.target.value?.trim()) {
          toast.warning("Invoice description can not contain only space");
          return;
        }
        if (mode === "fixed") {
          requirementSetterFuntion((prev) => {
            const temp = { ...prev };
            temp.revenues[index][fieldName] = event.target.value;
            return temp;
          });
        } else {
          requirementSetterFuntion((prev) => {
            const temp = { ...prev };
            temp.tmRevenues[index][fieldName] = event.target.value;
            return temp;
          });
        }

        // requirementSetterFuntion((prev) => {
        //   const temp = { ...prev };
        //   temp.revenues[index][fieldName] = event.target.value;
        //   return temp;
        // });

        break;

      default:
        break;
    }
  };

  const handleReadonlyMileStone = () => {
    const sumOfCosts = requirements.revenues.reduce((accumulator, ele) => {
      return (accumulator += parseInt(ele.cost));
    }, 0);
    if (sumOfCosts >= parseInt(requirements.totalCost)) return true;
    return false;
  };

  const totalSteps = () => {
    return steps.length;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const requirementsRevenuesIsEmpty =
    requirements.mode === "fixed"
      ? requirements?.revenues?.filter(
          (item) =>
            item?.cost &&
            item.invoiceDescription?.trim() &&
            item.milestoneName?.trim()
        ).length === requirements.revenues.length
      : requirements?.tmRevenues?.filter(
          (item) =>
            item?.cost &&
            item.invoiceDescription?.trim() &&
            item.resourceName?.trim()
        ).length === requirements.tmRevenues.length;

  console.log(requirementsRevenuesIsEmpty, "requirementsRevenuesIsEmpty");
  const checkTotalCostIsEqualtoRevenueCost =
    +requirements.totalCost ===
    requirements.revenues.reduce(
      (prev, curr) => (prev += parseInt(curr.cost)),
      0
    );
  const checkIsEmpty = (mode) => {
    console.log("modekoi", mode, checkTotalCostIsEqualtoRevenueCost);
    if (mode !== null && mode !== undefined) {
      switch (mode) {
        case "fixed": {
          const isFixedValid =
            !checkTotalCostIsEqualtoRevenueCost ||
            !requirementsRevenuesIsEmpty ||
            !requirements.currency ||
            !requirements.company ||
            !requirements.account ||
            !requirements.revenues.length;

          return isFixedValid;
        }
        default:
          const isDynamicValide =
            !requirements.mode ||
            !requirements.currency ||
            !requirements.company ||
            !requirements.account ||
            // // !requirements.revenues.length ||
            // !requirements.tmRevenues.length ||
            !requirementsRevenuesIsEmpty;
          return isDynamicValide;
      }
    }
    return true;
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await getAttributesByEntity("Projects");
  //       if (response.data.status) {
  //         setCustomAttributes(response.data.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [afterCreate]);

  return (
    <Dialog maxWidth="lg" fullWidth open={requirements.open} onClose={() => {}}>
      <DialogTitle>Proposal Budget</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  {/* <StepButton color="inherit" onClick={handleStep(index)}> */}
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* <Button onClick={handleReset}>Reset</Button> */}
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    sx={{ mb: 2 }}
                  ></Grid> */}

                  <>
                    {activeStep === 0 ? (
                      <Grid container spacing={1.2}>
                        <Grid
                          item
                          xs={1}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Modes:</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControl fullWidth>
                            {/* <FormLabel>Modes</FormLabel> */}
                            <RadioGroup
                              row
                              name="mode"
                              value={requirements.mode}
                              onChange={(event) => {
                                requirements.onModesChange();
                                handleCostAssignmentDialogState(event);
                              }}
                            >
                              {costModes.map((ele, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={ele.toLowerCase()}
                                  control={<Radio />}
                                  label={ele}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          {requirements.mode ? (
                            requirements.mode === "fixed" ? (
                              <DecidingFactor
                                label="Total Cost"
                                placeholder="Total Cost"
                                name="totalCost"
                                value={requirements.totalCost}
                                requirements={requirements}
                                setHomeCompany={setHomeCompany}
                                setHomeAccount={setHomeAccount}
                                handleIncludeGst={handleIncludeGst}
                                handleCostAssignmentDialogState={
                                  handleCostAssignmentDialogState
                                }
                                fromLeadPage={fromLeadPage}
                                addResourceButton={
                                  <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    onClick={() => {
                                      console.log("zero", requirements);
                                      if (
                                        parseInt(requirements.totalCost) === 0
                                      ) {
                                        toast.warn(
                                          "Total cost can not be zero"
                                        );
                                        return;
                                      } else {
                                        requirements.insertRevenueOneByOne();
                                      }
                                    }}
                                    sx={{ width: "30%" }}
                                    disabled={
                                      !requirements.totalCost ||
                                      !requirements.currency ||
                                      !requirements.company ||
                                      !requirements.account
                                    }
                                  >
                                    Add MileStone
                                  </Button>
                                }
                              />
                            ) : (
                              <DecidingFactor
                                label="Number Of Resources"
                                placeholder="Number Of Resources"
                                name="numberofResources"
                                value={requirements.numberofResources}
                                isCreateMode={true}
                                fromLeadPage={true}
                                setHomeAccount={setHomeAccount}
                                setHomeCompany={setHomeCompany}
                                handleIncludeGst={handleIncludeGst}
                                requirements={requirements}
                                handleCostAssignmentDialogState={
                                  handleCostAssignmentDialogState
                                }
                              />
                            )
                          ) : null}
                        </Grid>
                        {
                          // requirements.revenues.length ?
                          requirements.mode === "fixed" &&
                          requirements.revenues.length ? (
                            <Grid item xs={12}>
                              <Grid container spacing={1.2}>
                                {requirements.revenues.map((ele, index) => {
                                  return (
                                    <>
                                      {requirements.mode === "fixed" ? (
                                        <Grid
                                          item
                                          key={index}
                                          xs={
                                            requirements.mode === "fixed"
                                              ? 1
                                              : 0
                                          }
                                        >
                                          <IconButton
                                            size="small"
                                            sx={{ mt: "11.5px" }}
                                            onClick={() => {
                                              requirements.removeRevenues(
                                                requirements.revenues,
                                                index
                                              );
                                            }}
                                          >
                                            <RemoveIcon sx={{ color: "red" }} />
                                          </IconButton>
                                        </Grid>
                                      ) : null}
                                      <Grid
                                        item
                                        xs={
                                          requirements.mode === "fixed"
                                            ? 11
                                            : 12
                                        }
                                      >
                                        {" "}
                                        <CostDistributingListing
                                          key={index}
                                          costAndResources={ele}
                                          index={index}
                                          requirements={requirements}
                                          fieldHandler={
                                            handleCostAndInvoiceDescription
                                          }
                                        />{" "}
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Grid container spacing={1.2}>
                                {requirements.tmRevenues.map((ele, index) => {
                                  return (
                                    <>
                                      {requirements.mode === "fixed" ? (
                                        <Grid
                                          item
                                          xs={
                                            requirements.mode === "fixed"
                                              ? 1
                                              : 0
                                          }
                                        >
                                          <IconButton
                                            size="small"
                                            sx={{ mt: "11.5px" }}
                                            onClick={() => {
                                              requirements.removeRevenues(
                                                requirements.revenues,
                                                index
                                              );
                                            }}
                                          >
                                            <RemoveIcon sx={{ color: "red" }} />
                                          </IconButton>
                                        </Grid>
                                      ) : null}
                                      <Grid
                                        item
                                        xs={
                                          requirements.mode === "fixed"
                                            ? 11
                                            : 12
                                        }
                                      >
                                        {" "}
                                        <CostDistributingListing
                                          key={index}
                                          costAndResources={ele}
                                          index={index}
                                          requirements={requirements}
                                          fieldHandler={
                                            handleCostAndInvoiceDescription
                                          }
                                        />{" "}
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          )
                        }
                        {requirements.mode === "fixed" &&
                        requirements.revenues.length ? (
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                            item
                            xs={12}
                          >
                            <Button
                              startIcon={<AddIcon />}
                              variant="contained"
                              onClick={requirements.insertRevenueOneByOne}
                              disabled={
                                !(
                                  requirements.revenues.filter(
                                    (ele) => ele.cost && ele.invoiceDescription
                                  ).length === requirements.revenues.length
                                ) || handleReadonlyMileStone()
                              }
                            >
                              Add MileStone
                            </Button>
                          </Grid>
                        ) : null}
                      </Grid>
                    ) : activeStep === 1 ? (
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              gutterBottom
                              color="primary"
                            >
                              {`${projectDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <ProjectStaticAttribute
                              lead={lead}
                              projectData={projectData}
                              setProjectData={setProjectData}
                              skills={projectSkills}
                              setSkills={setProjectSkills}
                              techStacks={projectTechStacks}
                              setTechStacks={setProjectTechStacks}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {customAttributes.length ? (
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Typography
                                variant="h5"
                                gutterBottom
                                color="primary"
                              >
                                {`${projectDynamicTitle?.displayName.singularName.toUpperCase()} DETAILS`}
                              </Typography>
                            </Grid>
                            {customAttributes.map((attribute, index) => (
                              <Grid item xs={6} key={index}>
                                <EntityAttributeFields
                                  componentIndex={index}
                                  componentDeatils={attribute}
                                  allAttributes={customAttributes}
                                  setterFunction={setCustomAttributes}
                                  entityName={"Project"}
                                  toEdit={toEdit}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <>
                            <Typography
                              variant="subtitle1"
                              fontSize="12px"
                              color="gray"
                            >
                              Note: Custom attributes are not available for
                              projects. After clicking the 'ADD' button, the
                              lead will be updated with the creation of Proposal
                              Budget in Project.
                            </Typography>
                          </>
                        )}
                      </Grid>
                    )}
                  </>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {activeStep > 0 && (
                      <IconButton
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1, mt: 0 }}
                        // variant="contained"
                        size="medium"
                      >
                        <KeyboardArrowLeftIcon sx={{ fontSize: 33 }} />
                      </IconButton>
                    )}
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* {open && !isTrue && activeStep === 0 && trigger !== "" && ( */}
                    {activeStep === 0 && (
                      <IconButton
                        onClick={handleNext}
                        sx={{
                          "&.Mui-disabled": {
                            // background: "#eaeaea",
                            color: "#c0c0c0",
                          },
                          color: "#1976d2",
                          mr: 1,
                          mt: 0,
                        }}
                        size="medium"
                        disabled={checkIsEmpty(requirements.mode)}
                      >
                        <KeyboardArrowRightIcon sx={{ fontSize: 33 }} />
                      </IconButton>
                    )}

                    {activeStep === 1 && (
                      <IconButton
                        onClick={handleNext}
                        sx={{
                          "&.Mui-disabled": {
                            // background: "#eaeaea",
                            color: "#c0c0c0",
                          },
                          color: "#1976d2",
                          mr: 1,
                          mt: 0,
                        }}
                        size="medium"
                        disabled={
                          !projectData.title ||
                          !projectData.details ||
                          !projectData.specification
                        }
                      >
                        <KeyboardArrowRightIcon sx={{ fontSize: 33 }} />
                      </IconButton>
                    )}
                    {/* )} */}
                    {/* {isActionOpen &&
                    !isTrue &&
                    activeStep === 1 &&
                    action !== "" && (
                      <Button
                        onClick={handleNext}
                        sx={{ mr: 1, mt: 0 }}
                        variant="contained"
                        size="large"
                      >
                        Next
                      </Button>
                    )} */}
                    {/* {activeStep === 2 && (
                    <Button
                      sx={{ mr: 1, mt: 0 }}
                      // onClick={handleSaveRule}
                      variant="contained"
                      size="large"
                    >
                      Save
                    </Button>
                  )} */}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            requirements.onClose();
            onCancelLeadStatusChange();
            handleReset();
            setHomeCompany("");
            setHomeAccount("");
          }}
        >
          CANCEL
        </Button>
        <Button
          // disabled={
          //   !requirements.mode ||
          //   !requirements.currency ||
          //   !requirements.revenues.length ||
          //   (!requirements.numberofResources && !requirements.totalCost)
          // }
          disabled={
            activeStep === 0
              ? true
              : activeStep === 1
              ? true
              : activeStep === 2
              ? false
              : false
          }
          onClick={handleAdd}
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CostAssignmentDialog;
