import React, { useCallback, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import {
  Grid,
  Card,
  Typography,
  CardContent,
  TextField,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  touchRippleClasses,
  contact,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries } from "../../services/common.service.js";
import {
  createContact,
  updateContact,
} from "../../services/contacts.service.js";
import Box from "@mui/material/Box";
import { updateLead } from "../../services/leads.service.js";
import { getUsersByRoles } from "../../services/users.service.js";
import DOMPurify from "dompurify";
import ReactQuill from "react-quill";
import parser from "html-react-parser";
import "./Lead.view.css";
import { useContext } from "react";
import { AuthContext } from "../../context/auth.context.js";
import { getTechStacks } from "../../services/projects.service.js";
import { useDynamicLeadTitle } from "../../pages/Leads/Leads.js";
import EntityAttributeFields from "../Shared/Custom/EntityAttributeFields.js";
import validateUniqueAttributes from "../../utils/customAttributesUtils.js";
import { capitalizedString } from "../../utils/capitalizedString.js";
import CostAssignmentDialog from "../Dialogs/CostAssignmentDialog/CostAssignmentDialog.js";
import removeExtraWhiteSpace from "../../utils/removeExtraWhiteSpace.js";
import { validateString } from "../../utils/validateString.js";
import getAttributesByEntity from "../../services/entity.attribute.services.js";
import { getGst } from "../../services/attributes.service.js";
// import React, { PureComponent } from 'react'

// export default class lead.view extends PureComponent {
//   static propTypes = {}

//   render() {
//     return (
//       <div>lead.view</div>
//     )
//   }
// }

const { htmlToText } = require("html-to-text");

const TAG = "lead.view";

const LeadInfo = ({
  homeCompany,
  homeAccount,
  setHomeCompany,
  setHomeAccount,
  toEdit,
  setToEdit,
  id,
  leadId,
  contactId,
  isEditPage,
  lead,
  setLead,
  leadStatus,
  setLeadStatus,
  TempLead,
  setTempLead,
  setUpdateLeadCalled,
  contact,
  callGetSalesOwnerInfoMethod,
  customAttributes,
  setCustomAttributes,
  setIactionedApiCalled,
  costAssignmentDependency,
  costAssignment,
  contactInfoObj,
  handleIncludeGst,
  projectSkills,
  setProjectSkills,
  projectTechStacks,
  setProjectTechStacks,
  projectData,
  setProjectData,
  projectCustomAttributes,
  setProjectCustomAttributes,
}) => {
  const leadDynamicTitel = useDynamicLeadTitle();
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };
  const result = parser(lead.description ? lead.description : "");
  const [userType, setUserType] = useState([]);
  const [display, setdisplay] = useState("");
  const [updateButton, setUpdateButton] = useState(false);
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const [techStacks, setTechStacks] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDrop, setOpenDrop] = React.useState(false);
  const [gstValue, setGstValue] = useState({});

  const [showAddSkillDialog, setShowAddSkillDialog] = React.useState(false);
  const [skillName, setSkillName] = useState("");
  const [statusReason, setStatusReason] = useState("");
  const [loaderDialog, setLoaderDialog] = React.useState(false);

  const fetchTechStacks = async () => {
    try {
      const response = await getTechStacks();
      if (response.data.status) {
        setTechStacks(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getGSTData = async () => {
    try {
      const response = await getGst();
      if (response.data.status) {
        const data = response.data.data;

        console.log("data1111", data);
        setGstValue({ ...data[0] });
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getUsersByRoles(2).then((response) => {
      if (response.data.status) {
        console.log(`${TAG}.useEffect.getUsersByRoles: `, response.data);
        setUserType(response.data.data);
      }
    });
    fetchTechStacks();
    getGSTData();
  }, []);

  const handleChange = (e) => {
    setLead((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeStatus = (e) => {
    if (e.target.value === "close") {
      const checkMandatoryFieldStatus = validate(lead);
      if (!checkMandatoryFieldStatus) {
        return;
      }
      const attributeValidate = attributeValidateFunction(customAttributes);
      if (!attributeValidate) {
        return;
      }
      costAssignmentDependency((prev) => ({ ...prev, open: true }));
      (async () => {
        try {
          const response = await getAttributesByEntity("Projects");
          if (response.data.status) {
            setProjectCustomAttributes(response.data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    setLeadStatus(e.target.value);
  };

  const handleChangeDesc = (e) => {
    setLead((prevState) => ({
      ...prevState,
      description: e,
    }));
  };

  const handleShowAddSkillDialog = () => {
    setShowAddSkillDialog(true);
  };
  const handleClickOpenDrop = () => {
    setOpenDrop(true);
  };

  const handleHideAddSkillDialog = () => {
    setShowAddSkillDialog(false);
  };

  const reasonDialogClose = () => {
    setLeadStatus(lead.status);
    setOpenDrop(false);
  };

  const handleAddSkill = () => {
    setLead((prevState) => ({
      ...prevState,
      skills: [...prevState.skills, skillName],
    }));
    if (skillName.length === 0) {
      toast("Skill cannot be empty");
    } else {
      // UpdateProject();
      setSkillName("");
    }
  };

  const handleEmptySkills = (e) => {
    console.log(e.target.value);
    if (e.target.value !== "" && e.target.value.trim() !== "") {
      setSkillName(e.target.value);
    }
  };

  const addNewSkillAndShowChip = () => {
    console.log("Inside button click", skillName);
    if (skillName !== "") {
      setLead((prevState) => ({
        ...prevState,
        skills: [...prevState.skills, { name: skillName }],
      }));
      setSkillName("");
    }
    // UpdateProject();
    handleHideAddSkillDialog();
  };

  const handleEmptySkillsChip = (val) => {
    val.map((element, index) => {
      if (element.name.length > 0) {
        return element;
      }
    });
  };
  console.log(contactInfoObj, "mycontactinfo");
  let gstData = [];
  const gstCollect = (state) => {
    if (costAssignment.gstIncluded && state !== undefined && state !== "") {
      if (state === "Odisha") {
        const gstInfo = {
          igst: 0.0,
          cgst: gstValue.cgst,
          sgst: gstValue.sgst,
        };
        gstData.push(gstInfo);
        return gstData;
      } else {
        const gstInfo = {
          igst: gstValue.igst,
          cgst: 0.0,
          sgst: 0.0,
        };
        gstData.push(gstInfo);
        return gstData;
      }
    } else {
      const gstInfo = {
        igst: 0.0,
        cgst: 0.0,
        sgst: 0.0,
      };
      gstData.push(gstInfo);
      return gstData;
    }
  };

  function checkCountryCurrency(companyDetails) {
    if (
      companyDetails?.companyCountry === "India" ||
      costAssignment?.currency === "INR (₹)"
    ) {
      return true;
    }
    return false;
  }

  const provideProposalBudget = (mode) => {
    switch (mode) {
      case "fixed":
        return {
          mode: mode,
          currency: costAssignment.currency,
          // gstIncluded: checkCountryCurrency({
          //   companyCountry: contactInfoObj[0]?.company?.country,
          // }),
          gstIncluded: costAssignment.gstIncluded,
          gstApplicable: costAssignment.gstApplicable,
          leadId: leadId,
          companyType: homeCompany,
          bank: homeAccount,
          gst: gstCollect(contactInfoObj[0]?.company?.state),
          fixed: {
            active: true,
            totalCost: parseInt(costAssignment.totalCost),
            milestones: costAssignment.revenues,
            noOfMilestones: costAssignment.revenues.length,
          },
        };

      default:
        return {
          mode: mode,
          currency: costAssignment.currency,
          // gstIncluded: checkCountryCurrency({
          //   companyCountry: contactInfoObj[0]?.company?.country,
          // }),
          gstIncluded: costAssignment.gstIncluded,
          gstApplicable: costAssignment.gstApplicable,
          leadId: leadId,
          companyType: homeCompany,
          bank: homeAccount,
          gst: gstCollect(contactInfoObj[0].company?.state),
          dynamic: {
            active: true,
            noOfResources: costAssignment.numberofResources,
            costEstimation: costAssignment.tmRevenues,
          },
        };
    }
  };

  const CheckAndGetChangedLeadData = () => {
    // console.log(lead, customAttributes);
    const updatableKeys = [
      "techOwner",
      "summary",
      "skills",
      "description",
      "status",
      "reason",
    ];

    const keys = Object.keys(lead);
    const leadData = {};
    keys.forEach((key) => {
      if (updatableKeys.includes(key) && lead[key] !== TempLead[key])
        leadData[key] = lead[key];
    });
    const updatedAttributes = validateUniqueAttributes(
      lead.attributes,
      customAttributes
    );
    if (updatedAttributes.length) {
      leadData["attributes"] = [...customAttributes];
    }
    return leadData;
  };

  function attributeValidateFunction(customAttributes) {
    let flag = true;
    customAttributes.forEach((ele) => {
      if (ele.isRequired && !ele["selectedValues"][0]) {
        toast(`${ele.name} can not be empty`);
        flag = false;
      }

      if (ele.uiType === "TextField") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 30;
        console.log("eleereer", isvalidelengthString);
        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 30 characters`);
          flag = false;
        }
      }

      if (ele.uiType === "multiLineTextfield") {
        let isvalidelengthString = ele["selectedValues"][0]?.length > 100;

        if (isvalidelengthString) {
          toast(`${ele.name} should not be more than 100 characters`);
          flag = false;
        }
      }
    });
    return flag;
  }

  function validate(company) {
    const fields = ["summary", "techOwner"];
    let flag = true;
    Object.keys(company).forEach((ele) => {
      if (fields.includes(ele) && !company[ele]) {
        toast(`${capitalizedString(ele)} can not be empty`);
        flag = false;
      }
    });
    return flag;
  }

  const UpdateLead = async () => {
    console.log("update lead cost");
    console.log("gstData");
    const tempLeadData = { ...lead };
    tempLeadData.status = leadStatus;
    const leadData = CheckAndGetChangedLeadData();
    const isStatusChanged = lead.status === leadStatus ? false : true;
    if (isStatusChanged) {
      leadData.status = leadStatus;
      if (leadStatus === "close") {
        leadData["costEstimation"] = provideProposalBudget(costAssignment.mode);
      }
    }

    costAssignment.onClose();

    if (leadData.hasOwnProperty("skills")) {
      leadData.skillsArray = leadData.skills.map((skill) => skill);
      delete leadData.skills;
    }

    const checkMandatoryFieldStatus = validate(leadData);
    if (!checkMandatoryFieldStatus) {
      return;
    }
    const attributeValidate = attributeValidateFunction(customAttributes);
    if (!attributeValidate) {
      return;
    }

    if (Object.keys(leadData).length > 0) {
      try {
        setLoaderDialog(true);
        setUpdateButton(true);
        const response = await updateLead(id, leadId, {
          ...leadData,
          attributes: [...customAttributes],
          projectData,
          projectCustomAttributes,
        });
        if (response.data.status) {
          setLoaderDialog(false);
          callGetSalesOwnerInfoMethod();
          console.log(`${TAG}.UpdateLead.response: `, response);
          setTempLead(tempLeadData);
          setIactionedApiCalled(true);
          toast(
            `${leadDynamicTitel?.displayName.singularName} successfully updated`
          );
          setToEdit(true);
          setUpdateButton(false);
          // setLoaderDialog(false);
          setUpdateLeadCalled(false);
          costAssignment.onClose();
        }
        // } {else {
        //   setUpdateButton(false);
        //   setToEdit(true);
        // }}
      } catch (error) {
        console.log(`${TAG}.UpdateLead.error: `, error);
        toast(error.response.data.message);
        // setToEdit(true);
        // setUpdateButton(false)
        setLoaderDialog(false);
        setToEdit(false);
        setUpdateButton(false);
        // setLoaderDialog(false);
        costAssignment.onClose();
      }
    } else {
      toast("Nothing to update");
      setToEdit(false);
      setLoaderDialog(false);
      setUpdateButton(false);
      // setLoaderDialog(false);
      costAssignment.onClose();
    }
  };

  console.log(statusReason);

  return (
    <Card
      className="child"
      elevation={3}
      variant="outlined"
      sx={{ minWidth: "auto", minHeight: "auto" }}
    >
      <CostAssignmentDialog
        requirements={costAssignment}
        setHomeCompany={setHomeCompany}
        setHomeAccount={setHomeAccount}
        requirementSetterFuntion={costAssignmentDependency}
        handleIncludeGst={handleIncludeGst}
        onCancelLeadStatusChange={() => {
          setLeadStatus(lead.status);
        }}
        handleAdd={UpdateLead}
        lead={lead}
        projectData={projectData}
        setProjectData={setProjectData}
        projectSkills={projectSkills}
        setProjectSkills={setProjectSkills}
        projectTechStacks={projectTechStacks}
        setProjectTechStacks={setProjectTechStacks}
        customAttributes={projectCustomAttributes}
        setCustomAttributes={setProjectCustomAttributes}
        fromLeadPage={true}
        parentCompanyDetails={contactInfoObj[0]?.company}
      />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "20px",
          }}
        >
          {isEditPage ? (
            (lead.status === "close" && lead.isProjectCreated === false) ||
            lead.status !== "close" ? (
              currentUser._id === lead.techOwner ||
              currentUser.role === "ADMIN" ||
              currentUser._id === contact.salesOwner ? (
                toEdit ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      style={{ marginRight: "28px" }}
                      variant="contained"
                      fontSize="small"
                      startIcon={<EditIcon />}
                      size="large"
                      onClick={() => {
                        setToEdit(false);
                        setdisplay("");
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Button
                      style={{ marginRight: "28px" }}
                      variant="contained"
                      size="large"
                      startIcon={<SaveIcon />}
                      disabled={updateButton}
                      onClick={() => {
                        //UpdateContact();
                        UpdateLead();
                      }}
                    >
                      Save
                    </Button>
                    <div>&nbsp;</div>
                    <Button
                      style={{ display: display }}
                      variant="contained"
                      startIcon={<CancelIcon />}
                      onClick={() => {
                        setLead({ ...TempLead });
                        // setToEdit(false);
                        setToEdit(true);
                        setdisplay("none");
                        setLeadStatus(lead.status);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )
              ) : null
            ) : null
          ) : null}
        </Grid>
      </Grid>

      <CardContent>
        <FormControl fullWidth>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Grid container spacing={leadStatus === "drop" ? 3 : 6}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    // disabled={toEdit}
                    inputProps={{
                      readOnly: isEditPage ? (toEdit ? true : false) : false,
                    }}
                    label="Summary"
                    variant="outlined"
                    placeholder="Summary"
                    type="text"
                    name="summary"
                    required
                    value={lead.summary}
                    fullWidth
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Autocomplete
                      readOnly={toEdit ? true : false}
                      multiple
                      freeSolo
                      id="tags-outlined"
                      // options={techStacks.length === 0 ? ["ADD NEW"] : techStacks}
                      options={techStacks}
                      getOptionLabel={(option) => option.name}
                      // getOptionLabel={(option) => { console.log("lead.view.skills.option: ", option); return option?.name || option }}
                      renderOption={(props, option) => {
                        console.log(
                          `${TAG}.skillsAutoComplete.renderOption: `,
                          option
                        );
                        return (
                          <Grid container spacing={0}>
                            {option.name === "+ Add" ? (
                              <Grid item xs={12}>
                                <Button
                                  fullWidth
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    size: "large",
                                  }}
                                  onClick={handleShowAddSkillDialog}
                                >
                                  ADD NEW
                                </Button>
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <li {...props} style={{ borderColor: "white" }}>
                                  {option.name}
                                </li>
                              </Grid>
                            )}
                          </Grid>
                        );
                      }}
                      value={
                        lead.skills > 0
                          ? handleEmptySkillsChip(lead.skills)
                          : lead.skills
                      }
                      filterSelectedOptions={true}
                      onChange={(event, newValue, reason) => {
                        // console.log(`${TAG}.onChange: `, lead.skills, newValue);
                        const hasDuplicates = (newSkills) => {
                          const skillCounts = newSkills.reduce((acc, skill) => {
                            const skillName = skill.name.toLowerCase();
                            acc[skillName] = (acc[skillName] || 0) + 1;
                            return acc;
                          }, {});

                          return Object.values(skillCounts).some(
                            (count) => count > 1
                          );
                        };

                        function setChips(newValueArray) {
                          // console.log(
                          //   checkForDuplicates(newValueArray),
                          //   lead.skills,
                          //   ".onChange:"
                          // );

                          if (hasDuplicates(newValueArray)) {
                            toast.warn(
                              "You cannot add the same skills, please choose a new one"
                            );
                            return;
                          }

                          setLead((prevState) => ({
                            ...prevState,
                            skills: [...newValueArray],
                          }));
                        }
                        switch (reason) {
                          case "createOption":
                            console.log("");
                            newValue.forEach((e) => {
                              if (typeof e === "string" && e.trim() !== "") {
                                let newData = { name: e };
                                newValue.pop(e);

                                setChips([...newValue, newData]);
                              }
                            });
                            // let newData = { name: newValue.pop() }
                            // setChips([...newValue, newData])
                            break;
                          case "removeOption":
                          case "selectOption":
                          case "clear":
                            setChips(newValue);
                            break;
                          default:
                            break;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Skills"
                          placeholder="Click to Add"
                        />
                      )}
                    ></Autocomplete>
                    <span
                      style={{
                        fontSize: "10px",
                        marginLeft: "8px",
                        color: "#808080",
                      }}
                    >
                      *To Add An Unregistered Chip, Write Its Name & Hit Enter
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        // disabled={toEdit}
                        inputProps={{
                          readOnly: isEditPage
                            ? toEdit
                              ? true
                              : false
                            : false,
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        name="status"
                        value={leadStatus}
                        // value={contact.data.source}
                        // defaultOpen="false"
                        // InputLabelProps={{ shrink: obj.source ? true : false }}
                        onChange={handleChangeStatus}
                      >
                        <MenuItem value={"lead"}>Lead</MenuItem>
                        <MenuItem value={"prospect"}>Prospect</MenuItem>
                        <MenuItem value={"opportunity"}>Opportunity</MenuItem>
                        <MenuItem
                          value={"drop"}
                          onClick={() => {
                            setStatusReason(lead.reason);
                            handleClickOpenDrop();
                          }}
                        >
                          Drop
                        </MenuItem>
                        <MenuItem value={"close"}>Closure</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* techowner */}
                  <Box>
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-sales-owner-label">
                        Tech Owner
                      </InputLabel>
                      <Select
                        // disabled={toEdit}
                        inputProps={{
                          readOnly: isEditPage
                            ? toEdit
                              ? true
                              : false
                            : false,
                        }}
                        labelId="demo-simple-select-sales-owner-label"
                        id="demo-simple-select-sales-owner"
                        label="techOwner"
                        name="techOwner"
                        value={lead.techOwner}
                        onChange={handleChange}
                        // onClick={techOwnerclick}
                      >
                        {userType?.map((e) => {
                          if (
                            (e.role === "ADMIN" ||
                              e.role === "DELIVERY_LEAD") &&
                            e.active_status === true
                          )
                            return (
                              <MenuItem key={e._id} value={e._id}>
                                {e.name}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {leadStatus === "drop" && lead.reason?.trim().length && (
                  <Grid item xs={12}>
                    <TextField
                      name="Reason"
                      label="Reason"
                      fullWidth
                      value={lead.reason?.trim()}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div class="material-textfield">
                    <ReactQuill
                      style={{
                        // border: "50%",
                        height: "53vh",
                        overflowY: toEdit ? "clip" : "hidden",
                      }}
                      value={lead.description}
                      name="description"
                      onChange={handleChangeDesc}
                      readOnly={isEditPage ? toEdit : true}
                      className={toEdit ? "app blurred-editor " : "app"}
                    />
                    <label>Description</label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {customAttributes.length ? (
            <Grid container spacing={4} mt="2px">
              {customAttributes.map((attribute, index) => (
                <Grid item xs={6} key={index}>
                  <EntityAttributeFields
                    componentIndex={index}
                    componentDeatils={attribute}
                    allAttributes={customAttributes}
                    setterFunction={setCustomAttributes}
                    entityName={"Lead"}
                    isEditPage={isEditPage}
                    toEdit={toEdit}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
        </FormControl>
      </CardContent>
      <Dialog open={showAddSkillDialog} onClose={handleHideAddSkillDialog}>
        <DialogTitle>Add</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new skills write its name in the below textbox
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="skills"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleEmptySkills(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleHideAddSkillDialog}>
            CANCEL
          </Button>
          <Button onClick={addNewSkillAndShowChip}>ADD</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={openDrop} onClose={() => {}}>
        {" "}
        <DialogTitle>
          {`Reason for Droping the ${leadDynamicTitel?.displayName.singularName}?`}
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            type="text"
            variant="standard"
            label="Reason"
            fullWidth
            value={statusReason || ""}
            onChange={(e) => {
              if (e.target.value && e.target.value?.length > 80) {
                toast.warning("Reason can not be more than 80 characters");
                return;
              } else if (e.target.value && !e.target.value.trim()) {
                toast.warning("Reason can not contain only spaces");
                return;
              } else {
                console.log("else");
                setStatusReason(e.target.value);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reasonDialogClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={(e) => {
              let iswhiteValueContain = removeExtraWhiteSpace(statusReason);

              if (!validateString(iswhiteValueContain)) {
                toast("Reason can not be empty");
                return;
              }
              if (!statusReason) {
                toast("Provide a specific reason");
              } else {
                setLead((prev) => {
                  const temp = { ...prev };
                  temp.reason = iswhiteValueContain;
                  return temp;
                });
                toast("Reason noted successfully");
                setOpenDrop(false);
              }
              console.log(lead);
            }}
          >
            ADD
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={loaderDialog}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingBottom: "20px",
          }}
        >
          Loading... Please Wait
        </DialogActions>
      </Dialog>
    </Card>
  );
};

function convertToPlain(html) {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

export default LeadInfo;
